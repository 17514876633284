import * as listingHelpers from '@/helpers/listing';

/**
 * Pick transformation resource to apply.
 */
export const applyEntityTransform = (values, rsc, solr) => {
  if (!values) {
    // console.warn("applyEntityTransform: NO DATA LOADED");
    return values;
  }
  let data, new_data = [], restapi, resAsArray, new_val;

  restapi = values.data ? false : true;
  resAsArray = (values instanceof Array || values.data instanceof Array) ? true : false;

  data = values.data || values;
  if (!(data instanceof Array)) {
    data = [data];
  }

  data.map((res) => {
    new_val = res;

    if (solr) {
      if (rsc?.includes('listing') || res.its_price || res.ss_mls_num) {
        new_val = listingSolr(res);
      }
      else {
        console.warn('NO TRANSFORMATION LOGIC FOUND FOR CONTENT FROM SOLR');
      }
    }
    else if (restapi && !rsc) {
      if (!res.isUser && (res.name || res.isTerm)) {
        new_val = taxonomyDrupalTransform(res);
      }
      else if (['listings', 'sfhouse', 'rentals'].includes(res.type)) {
        new_val = listingDrupalTransform(res);
      }
      else {
        new_val = nodeDrupalTransform(res);
      }
    }
    else {
      if ((['listings', 'listing', 'sfhouse', 'rentals'].includes(rsc)) || ['listings', 'sfhouse', 'rentals'].includes(res.type)) {
        new_val = listingDrupalTransform(res);
      }
      else if ((rsc?.includes('taxonomy')) || (res.relationships?.vocab)) {
        new_val = taxonomyDrupalTransform(res);
      }
      else if ((res?.type?.includes('bc--')) || (res.reusable)) {
        new_val = blockDrupalTransform(res);
      }
      else {
        new_val = nodeDrupalTransform(res);
      }
    }

    new_data.push(new_val);
  });

  return resAsArray ? new_data : new_data[0];
};

/**
 * Transforms data from the backend into something more
 * beautiful and readable on the frontend.
 */
export const nodeDrupalTransform = (data) => {
  if (!data/* || !data.attributes*/) {
    console.warn("TRANSFORM RANDOM NODE DATA: NOTHING TO TRANSFORM");
    return data;
  }

  const nodeprops = data.attributes ? data.attributes : data;
  var new_data = data;
  delete new_data['attributes'];
  delete new_data['relationships'];

  var internal = nodeprops.internalId ? listingHelpers.internalURL(nodeprops.internalId) : '';
  var alias = data.rest_alias ? data.rest_alias : (data.path?.alias ? data.path.alias : internal);

  new_data = {
    ...new_data,
    id: data.id,
    nid: nodeprops.internalId || data.internalId || null,
    alias: alias || nodeprops.path?.alias || '',
    title: data.title || '',
    url: listingHelpers.url(data.type, data.id),
    internal: internal,
    summary: (data.summary?.processed || data.summary || ''),
    image_styles: data.image?.imageFile?.links || [],
  };

  let body_val = (typeof data.body?.processed !== 'undefined') ? data.body?.processed : '';
  if (typeof body_val !== 'string' && data.body?.value) {
    body_val = data.body.value;
  }
  else if (typeof body_val !== 'string' && typeof data.body === 'string') {
    body_val = data.body;
  }
  if (body_val && body_val?.includes(`"json"`)) {
    new_data = {...new_data, body_json: JSON.parse(body_val) || {}};
  }
  else {
    new_data = {...new_data, body: body_val || ''};
  }

  if (data.para?.length > 0) {
    new_data = {...new_data, para: paragraphsDrupalTransform(data.para)};
  }
  if (data.paraTop?.length > 0) {
    new_data = {...new_data, paraTop: paragraphsDrupalTransform(data.paraTop)};
  }

  if (data?.image?.imageFile?.uri?.url) {
    const env = (process?.env.NEXT_PUBLIC_BACKEND_URL) ? process.env.NEXT_PUBLIC_BACKEND_URL : '//cms.potnets.com';
    new_data = {
      ...new_data,
      imageOrig: env + (data.image.imageFile.uri.url) || '',
      imgTitle: data.image?.relationships?.imageFile?.data?.meta?.title || data.title || '',
      imgAlt: data.image?.relationships?.imageFile?.data?.meta?.alt || data.title || '',
      imgHeight: data.image?.relationships?.imageFile?.data?.meta?.height || 0,
      imgWidth: data.image?.relationships?.imageFile?.data?.meta?.width || 0,
    };
  }

  if (data.slides?.length > 0) {
    let slides = [];
    if (data.slides && data.slides.length > 0 && data.slides[0].src !== undefined) {
      slides = data.slides;
    }
    else {
      slides = composeSliderMedia(data.slides);
    }
    new_data = {...new_data, slides: slides || []};

    if (!data?.image?.imageFile && slides?.length > 0) {
      new_data = {
        ...new_data, ...slides[0],
        imageOrig: slides[0].src || '',
      };
    }
  }

  if (data.category) {
    new_data = {...new_data, categoryTerm: applyEntityTransform(data.category, 'taxonomy')};
  }

  new_data.cityTerm = null;
  if (data.cityTerm) {
    new_data = {...new_data, cityTerm: [applyEntityTransform(data.cityTerm, 'taxonomy')]};
  }

  new_data.commumityTerm = null;
  if (data.commTerm) {
    new_data = {...new_data, commumityTerm: applyEntityTransform(data.commTerm, 'taxonomy')};
  }

  if (data.type == 'golf') {
    new_data.courseTerm = null;
    if (data.course) {
      new_data = {...new_data, courseTerm: applyEntityTransform(data.course, 'taxonomy')};
      delete new_data.course;
    }
  }

  new_data.listingRef = null;
  if (data.listingRef) {
    let redirect = data.listingRef?.path?.alias ? data.listingRef.path.alias : `/node/${data.listingRef.internalId}`;
    new_data = {...new_data, listingRef: redirect};
    delete new_data.listingRef;
  }

  return new_data;
};

/**
 * Transforms data from Solr into something more readable on the frontend.
 */
export const listingSolr = (data) => {
  if (!data) {
    console.warn("TRANSFORM LISTING SOLR DATA: NO DATA LOADED");
    return data;
  }

  let shortAddr = '';
  const commTermName = data.commTerm?.[0]?.name || data.commTerm?.name || data.commTerm || '';
  const addr_1 = data.housetitle || data.tm_X3b_en_addr1?.[0] || data.address_line1 || null;
  // const addr_1 = data.tm_X3b_en_addr1?.[0] || data.address_line1 || null;
  // let address_str = (addr_1 && addr_2) ? addr_1 + ', ' + addr_2 : addr_1;
  let address_str = addr_1;
  shortAddr = address_str;

  address_str = (address_str && commTermName && !['Floor Plans', 'Baywood'].includes(commTermName)) ? address_str + ', ' + commTermName : address_str;

  const uuid = data.ss_uuid || data.id || null;
  const id = data.its_nid || data.internalId || null;

  var return_data = data;
  return_data = {
    ...return_data,
    id: data.ss_uuid || data.id,
    internalId: id,
    nid: id,
    alias: data.ss_url && !data.ss_url.includes('node/') ? data.ss_url : data.rest_alias,
    title: data.ss_title || (data.tm_X3b_en_title && data.tm_X3b_en_title[0]) || data.title || '',
    shortAddr: shortAddr,
    address: address_str || (data.tm_X3b_en_title && data.tm_X3b_en_title[0]) || data.ss_title || data.title || '',
    url: uuid ? listingHelpers.url('listings', uuid) : '',
    internal: id ? listingHelpers.internalURL(id) : '',
    beds: parseInt(data.its_bed) || parseInt(data.beds) || 0,
    bathAll: parseFloat(data.its_bath_all) || parseFloat(data.bathAll) || 0,
    bathFull: data.its_bath_full || data.bathFull || 0,
    bathHalf: data.its_bath_half || data.bathHalf || 0,
    sqft: data.its_sqft || parseInt(data.sqft) || 0,
    price: data.its_price || parseInt(data.price) || 0,
    yearBuilt: data.its_year || parseInt(data.yearBuilt) || 0,
    mlsNum: data.ss_mls_num || data.mlsNum || '',
    imageOrig: data.ss_img_uri ? encodeURI(data.ss_img_uri.replace("public://", process.env.NEXT_PUBLIC_BACKEND_URL + '/sites/default/files/')) : data.imageOrig,
    saleType: data.ss_sale_type || data.saleType || '',
    lat: data.fts_lat || parseFloat(data.coord_lat),
    lon: data.fts_lon || parseFloat(data.coord_lon),
  };
  if (data.type == 'escapia_unit' || data.ss_content_type == 'escapia_unit') {
    return_data = {
      ...return_data, ...{
        address: data.title || data.ss_addr1 || '',
        maxOcc: data.its_maxocc || parseInt(data.its_maxocc) || 0,
        policies: data.sm_policies || [],
        bathAll: parseFloat(data.ss_bath_all) || parseFloat(data.fs_bath_all) || parseFloat(data.bathAll) || 0,
        rateTxt: data.ss_rate_txt || null,
        type: data.type || data.ss_type || 'rentals',
      }
    }
  }

  if (data.type == 'listings') {
    return_data = {
      ...return_data, ...{
        leaseFee: data.its_lease_fee || parseInt(data.leaseFee) || 0,
      }
    }
    if (data.homeStatus?.length) {
      return_data = {
        ...return_data,
        homeStatus: {
          name: data.homeStatus,
          title: data.homeStatus,
          type: "sfhomestatus"
        }
      };
    }
  }

  return return_data;
};

/**
 * Transforms data from Solr into something more readable on the frontend.
 */
export const contentNodesSolr = (data) => {
  if (!data) {
    console.warn("TRANSFORM NODES SOLR DATA: NO DATA LOADED");
    return data;
  }

  let return_data = {
    id: data.ss_uuid,
    internalId: data.its_nid?.toString() || null,
    nid: data.its_nid || null,
    contentType: data.ss_content_type || undefined,
    alias: data.ss_url && !data.ss_url.includes('node/') ? data.ss_url : '',
    internal: data.its_nid ? listingHelpers.internalURL(data.its_nid) : '',
    summary: (data.summary?.processed || data.summary || ''),
    title: (data.tm_X3b_en_title && data.tm_X3b_en_title[0]) || '',
  };

  if (return_data.contentType) {
    let body_val = (data.body?.processed) ? data.body.processed : false;
    if (!body_val && data.body?.value) {
      body_val = data.body.value;
    }
    else if (!body_val && data.body) {
      body_val = data.body;
    }
    else if (data.tm_X3b_en_body?.length) {
      body_val = data.tm_X3b_en_body[0];
    }
    return_data = {...return_data, body: (body_val || '')};

    if (data.tm_X3b_en_summary?.length > 0) {
      return_data = {...return_data, summary: data.tm_X3b_en_summary[0]};
    }

    if (data.sm_slides_uri?.length > 0) {
      return_data = {...return_data, imageOrig: data.sm_slides_uri[0].replace("public://", process.env.NEXT_PUBLIC_BACKEND_URL + '/sites/default/files/')};

      let slides = [];
      data.sm_slides_uri.map((s) => {
        slides.push(s.replace("public://", process.env.NEXT_PUBLIC_BACKEND_URL + '/sites/default/files/'))
      });
      return_data = {...return_data, slides: slides};
    }
  }

  return return_data;
};

/**
 * Transforms data from the backend into something more
 * beautiful and readable on the frontend.
 */
export const listingDrupalTransform = (data) => {
  if (!data) {
    console.warn("TRANSFORM LISTING DATA: NO DATA LOADED");
    return data;
  }

  const listprops = data.attributes ? data.attributes : data;
  var internal = listprops.internalId ? listingHelpers.internalURL(listprops.internalId) : '';
  var alias = data.rest_alias ? data.rest_alias : (data.path?.alias ? data.path.alias : internal);
  const env = (process?.env.NEXT_PUBLIC_BACKEND_URL) ? process.env.NEXT_PUBLIC_BACKEND_URL : '//cms.potnets.com';

  var new_data = data;
  delete new_data['attributes'];
  delete new_data['relationships'];

  var new_data = {
    ...new_data,
    id: data.id,
    nid: listprops.internalId || data.internalId || null,
    title: data.title || '',
    url: listingHelpers.url(data.type, data.id),
    internal: internal,
    sqft: (data.sqft && parseInt(data.sqft)) || 0,
  };
  if (data.type == 'listings') {
    new_data = {...new_data,
      saleType: data.saleType || '',
      price: (data.price && parseInt(data.price)) || 0,
      mlsNum: data.mlsNum || '',
      alias: alias || listprops.path || '',
      virtual: data.virtual || data.modelLink || null,
      imageOrig: data.imageOrig || null,
      image_styles: data.image?.imageFile?.links || [],
    };

    if (data.rest_alias) {
      new_data = {...new_data,
        lat: parseFloat(data.coord?.lat) || parseFloat(data.coord_lat) || '',
        lon: parseFloat(data.coord?.lon) || parseFloat(data.coord_lon) || '',
      };
    }

    if (data.video) {
      let emb_url = null;
      let new_emb_url = null;
      if (data.video?.videoEmbed) {
        const url = new URL(data.video?.videoEmbed);
        emb_url = url ? url?.pathname?.replace(/^\/+/, '').split('/') : null;
        if (emb_url && data.video?.videoEmbed?.includes('vimeo')) {
          if (emb_url.length > 1) {
            new_emb_url = `https://player.vimeo.com/video/${emb_url?.[0]}?h=${emb_url?.[1]}`
          }
          else {
            new_emb_url = `https://player.vimeo.com/video/${emb_url?.[0]}`
          }
        }
        else if (emb_url && data.video?.videoEmbed?.includes('youtu')) {
          new_emb_url = `https://www.youtube.com/embed${url?.pathname}`
        }
      }

      new_data = {...new_data,
        videoEmbed: new_emb_url,
      };
      delete new_data['video'];
    }
  }

  if (data.homeStatus) {
    new_data = {
      ...new_data,
      homeStatus: applyEntityTransform(data.homeStatus)
    };
  }
  if (data.cityTerm) {
    new_data = {...new_data, cityTerm: [applyEntityTransform(data.cityTerm)]};
  }

  let community_str = (typeof data.commTerm == 'string' && data.commTerm) || data.commTerm?.name || data.commTerm?.[0]?.name;
  if (data.commTerm && typeof data.commTerm !== 'string') {
    new_data = {...new_data, communityTerm: applyEntityTransform(data.commTerm)};
    delete new_data['commTerm'];
  }

  let address_str = null, street = '', city = '', zip = '', addr_state = '', shortAddr = '';

  // House Postal Address Drupal field
  let orig_addr = data?.listingRef?.address || data.address;
  let addr_community = community_str ? ', ' + community_str : '';

  if (orig_addr) {
    // House Title = SF House Name, otherwise Postal Address line1 = SF 911 Street Address
    address_str = data?.listingRef?.title || ((orig_addr && orig_addr.address_line1) ? orig_addr.address_line1 : null);
    street = address_str || '';
    city = orig_addr?.locality || '';
    shortAddr = address_str;
    address_str = (address_str && !['Floor Plans', 'Baywood'].includes(community_str)) ? address_str + addr_community : address_str;
    addr_state = orig_addr?.administrative_area || '';
    zip = orig_addr?.postal_code || '';
  }

  if (data.houseFiles?.length > 0) {
    let files = [];
    data.houseFiles.map((f) => {
      files.push({
        src: env + f?.docFile?.uri?.url,
      });
    });
    new_data = {...new_data, houseFiles: files};
  }

  if (data?.image?.imageFile?.uri?.url) {
    new_data = {
      ...new_data,
      imageOrig: env + (data.image.imageFile.uri.url) || '',
      imgTitle: data.image?.relationships?.imageFile?.data?.meta?.title || data.title || '',
      imgAlt: data.image?.relationships?.imageFile?.data?.meta?.alt || data.title || '',
      imgHeight: data.image?.relationships?.imageFile?.data?.meta?.height || 0,
      imgWidth: data.image?.relationships?.imageFile?.data?.meta?.width || 0,
    };
  }

  let body_val = (data.body?.processed) ? data.body.processed : false;
  if (!body_val && data.body?.value) {
    body_val = data.body.value;
  }
  else if (!body_val) {
    body_val = data.body;
  }
  new_data = {...new_data, body: (body_val || '')};

  if (data.body?.summary) {
    new_data = {...new_data, bodySummary: data.body.summary};
  }

  if (data.slides?.length > 0) {
    let slides = [];
    if (data.slides && data.slides.length > 0 && data.slides[0].src !== undefined) {
      slides = data.slides;
    }
    else {
      slides = composeSliderMedia(data.slides);
    }
    new_data = {...new_data, slides: slides};
  }

  if (data.type == 'sfhouse') {
    address_str = data?.title || ((orig_addr && orig_addr.address_line1) ? orig_addr.address_line1 : null);
    address_str = (address_str && !['Floor Plans', 'Baywood'].includes(community_str)) ? address_str + addr_community : address_str;

    new_data = {...new_data,
      shortAddr: shortAddr,
      address: address_str || data.title || '',
      street: street,
      city: city,
      state: addr_state,
      zip: zip,
      electricity: (Array.isArray(data.electricity) && data.electricity[0]) || (!Array.isArray(data.electricity) && data.electricity) || 0,
      yearBuilt: data.yearBuilt || 0,
      beds: data.beds || 0,
      lat: data.coord?.lat || data.coord_lat || '',
      lon: data.coord?.lon || data.coord_lon || '',
      bathAll: parseFloat(data.bathAll) || 0,
    };
  }

  if (data.type == 'rentals') {
    new_data = {...new_data,
      alias: alias || listprops.path || '',
      address: data.title || address_str || '',
      street: street,
      city: city,
      // state: addr_state,
      // zip: zip,
      yearBuilt: data.yearBuilt || 0,
      beds: data.beds || 0,
      bathAll: parseFloat(data.bathAll) || 0,
      lat: data.coord ? data.coord.lat : '',
      lon: data.coord ? data.coord.lon : '',
    };
    if (data.amenities) {
      new_data = {
        ...new_data,
        amenities: applyEntityTransform(data.amenities, 'taxonomy'),
      };
      // delete new_data['amenities'];
    }
    if (data.rateCalFile?.length > 0) {
      let files = [];
      data.rateCalFile.map((f) => {
        files.push({
          src: env + f?.docFile?.uri?.url,
        });
      });
      new_data = {...new_data, rateCalFile: files};
    }
  }

  if (data.listingRef) {
    new_data = {
      ...new_data,
      sfhouse: listingDrupalTransform(data.listingRef),
    };
  }
  delete new_data['attributes'];

  return new_data;
};

/**
 * Transforms data from taxonomies.
 */
export const taxonomyDrupalTransform = (data) => {
  if (!data) {
    console.warn("TRANSFORM TAXONOMY DATA: NO DATA LOADED");
    return data;
  }

  if (!(data instanceof Array)) {
    data = [data];
  }
  let qty = data.length, return_arr = [];

  data.map((res) => {
    var internal = '/taxonomy/term/' + res.internalId;
    var alias = res.rest_alias ? res.rest_alias : (res.path?.alias ? res.path.alias : internal);

    let new_term = {
      ...res,
      internalId: res['internalId'],
      id: res['id'],
      internal: internal,
      alias: alias,
      title: res['name'] || res['title'] || '',
    };

    let body_val = (res.body?.processed) ? res.body.processed : false;
    if (!body_val && res.body?.value) {
      body_val = res.body.value;
    }
    else if (!body_val) {
      body_val = res.body;
    }
    new_term = {...new_term, body: body_val || ''};

    if (res.slides?.length > 0) {
      let slides = [];
      if (res.slides[0].src !== undefined) {
        slides = res.slides;
      }
      else {
        slides = composeSliderMedia(res.slides);
      }
      new_term = {...new_term, slides: slides};
    }

    return_arr.push(new_term);
  });

  return qty == 1 ? return_arr[0] : return_arr;
};

/**
 * Transforms data from content blocks.
 */
export const blockDrupalTransform = (data) => {
  if (!data) {
    console.warn("TRANSFORM CONTENT BLOCK DATA: NO DATA LOADED");
    return data;
  }

  let new_data = {
    ...data,
    internalId: data['internalId'],
    id: data['id'],
    title: data.title?.processed || data.title || '',
  };

  let body_val = (data.body?.processed) ? data.body.processed : false;
  if (!body_val && data.body?.value) {
    body_val = data.body.value;
  }
  else if (!body_val) {
    body_val = data.body;
  }
  new_data = {...new_data, body: body_val || ''};

  if (data?.image?.imageFile?.uri?.url) {
    let styles = data.image?.imageFile?.links;
    new_data = {
      ...new_data,
      imageOrig: styles?.max_1200_width?.href || styles?.orig_optimized?.href || (env + data.image.imageFile.uri.url) || null,
      imgTitle: data.image?.relationships?.imageFile?.v?.meta?.title || data.title || '',
      imgAlt: data.image?.relationships?.imageFile?.v?.meta?.alt || data.title || '',
      imgHeight: data.image?.relationships?.imageFile?.v?.meta?.height || 0,
      imgWidth: data.image?.relationships?.imageFile?.v?.meta?.width || 0,
      image_styles: data.image?.imageFile?.links || [],
    };
    // delete new_data['image'];
  }

  delete new_data['attributes'];
  return new_data;
};

/**
 * Transforms data from the backend into something more
 * beautiful and readable on the frontend.
 */
export const paragraphsDrupalTransform = (data) => {
  if (!data) {
    console.warn("TRANSFORM PARAGRAPH DATA: NO DATA LOADED");
    return data;
  }

  data = Array.isArray(data) ? data : [data];
  let result = [];
  const env = (process?.env.NEXT_PUBLIC_BACKEND_URL) ? process.env.NEXT_PUBLIC_BACKEND_URL : '//cms.potnets.com';

  data.map((v, k) => {
    var new_data = v;
    delete new_data['attributes'];
    delete new_data['relationships'];

    if (v?.image?.imageFile?.uri?.url) {
      let styles = v.image?.imageFile?.links;
      new_data = {
        ...new_data,
        imageOrig: styles?.max_1200_width?.href || styles?.orig_optimized?.href || (env + v.image.imageFile.uri.url) || null,
        imgTitle: v.image?.relationships?.imageFile?.v?.meta?.title || v.title || '',
        imgAlt: v.image?.relationships?.imageFile?.v?.meta?.alt || v.title || '',
        imgHeight: v.image?.relationships?.imageFile?.v?.meta?.height || 0,
        imgWidth: v.image?.relationships?.imageFile?.v?.meta?.width || 0,
        image_styles: v.image?.imageFile?.links || [],
      };
      // delete new_data['image'];
    }

    let body_val = (typeof v.body?.processed !== 'undefined') ? v.body.processed : '';
    if (typeof body_val !== 'string' && v.body?.value) {
      body_val = v.body.value;
    }
    else if (typeof body_val !== 'string' && typeof v.body === 'string') {
      body_val = v.body;
    }
    if (body_val && body_val?.includes(`"json"`)) {
      new_data = {...new_data, body_json: JSON.parse(body_val) || {}};
    }
    else {
      new_data = {...new_data, body: body_val || ''};
    }

    if (v.para?.length > 0) {
      new_data = {...new_data, para: paragraphsDrupalTransform(v.para) || []};
    }

    if (v.slides?.length > 0) {
      let slides = [];
      if (v.slides && v.slides.length > 0 && v.slides[0].src !== undefined) {
        slides = v.slides;
      }
      else {
        slides = composeSliderMedia(v.slides);
      }
      new_data = {...new_data, slides: slides};
    }
    result.push(new_data);
  });

  return result;
};

/**
 * Drupal entities data array into List of links (for SideNav component).
 */
export const trimBodyText = (data, length, ellipsis) => {
  if (!data || typeof data !== 'string') {
    console.warn("Invalid data.Has to be a string!");
    return data;
  }

  let new_data = '';
  length = length || 300;
  ellipsis = ellipsis || false;

  new_data = data.replace(/<[^>]+>/g, '').substring(0, length);
  if (ellipsis) {
    new_data += '...';
  }
  // var decodedStripedHtml = he.decode(stripedHtml);

  return new_data;
};

/**
 * Drupal entities data array into List of links (for SideNav component).
 */
export const entitiesArrayDrupalSidenav = (data) => {
  if (!data) {
    console.warn("TRANSFORM ENTITIES ARRAY: NO DATA LOADED");
    return data;
  }

  var new_data = [];
  data.map((val, key) => {
    new_data.push({
      id: val.internalId,
      linkUrl: val.alias || val.internal || '#',
      linkText: val.title || val.name || '',
    });
  });

  return new_data;
};

export const composeSliderMedia = (images) => {
  const img_arr = [];
  images = images?.data || images;
  const env = process?.env.NEXT_PUBLIC_BACKEND_URL
      ? process.env.NEXT_PUBLIC_BACKEND_URL
      : '//cms.potnets.com';

  // For JSON view with included nested JSON data for terms:
  if (typeof images === 'string' && images.includes(`{"results"`)) {
    images = JSON.parse(images);
    if (images.results !== 'undefined' && images.results instanceof Array) {
      images = images.results;
    }
  }

  if (Array.isArray(images) && images?.length > 0) {
    images.map((val) => {
      // Data from JSON API endpoint:
      if (val?.imageFile?.uri?.url) {
        let img_obj = {
          imgCaption: val?.caption || '',
          imgTitle: val?.relationships?.imageFile?.data?.meta?.title,
          imgAlt: val?.relationships?.imageFile?.data?.meta?.alt,
          imgHeight: val?.relationships?.imageFile?.data?.meta?.height,
          imgWidth: val?.relationships?.imageFile?.data?.meta?.width,
          src: '',
          image_styles: val.imageFile?.links || [],
        };
        img_obj.src =
            img_obj.image_styles?.max_1200_width?.href ||
            img_obj.image_styles?.orig_optimized?.href ||
            env + val.imageFile.uri.url ||
            null;
        img_arr.push(img_obj);
      }
      // Data from REST views (array of Media objects with attrs: url, alt, title, etc.):
      else if (val?.imageOrig) {
        let img_obj = {
          imgCaption: val?.caption || '',
          imgTitle: val?.imgTitle || '',
          imgAlt: val?.imgAlt || '',
          imgHeight: val?.imgHeight || '',
          imgWidth: val?.imgWidth || '',
          src: val?.imageOrig || null,
        };
        img_arr.push(img_obj);
      }
    });
  }
  // For comma-separated URL values from JSON Drupal view:
  else if (typeof images == 'string') {
    const arr = images.split('|');
    arr.map((val, i) => {
      if (val) {
        let img_obj = {
          imgTitle: '',
          imgAlt: `Slide #${i}`,
          imgHeight: null,
          imgWidth: null,
          src: !val.includes('http') ? env + val.trim() : val,
          image_styles: [],
        };
        img_arr.push(img_obj);
      }
    });
  }
  return img_arr;
};

/**
 * Transforms data from the backend into something more
 * beautiful and readable on the frontend.
 */
export const metadata = (data) => {
  let res = {};
  const attr = data?.data ? data.data : data;
  if (attr) {
    res = {
      tags: attr?.['#attached']?.html_head || [],
    }
  }
  return (res);
};

export const pickImageStyle = (data, style, placeholder) => {
  style = style || 'max_510_height';
  let {
    imgAlt,
    imgTitle,
    imgWidth,
    imgHeight,
    imageOrig,
    image_styles,
    title
  } = data;

  let imageStyled = null, result, parsed_url = null;
  imgTitle = imgTitle || title;
  imgAlt = imgAlt || title;

  if (image_styles && Object.keys(image_styles).length > 0) {
    if (image_styles[style]?.href) {
      imageStyled = image_styles[style]?.href || null;
      imgWidth = image_styles[style]?.meta?.width || imgWidth || null;
      imgHeight = image_styles[style]?.meta?.height || imgHeight || null;
    }
    else if (image_styles?.orig_optimized?.href) {
      imageStyled = image_styles?.orig_optimized?.href || null;
      imgWidth = image_styles?.orig_optimized?.meta?.width || imgWidth || null;
      imgHeight = image_styles?.orig_optimized?.meta?.height || imgHeight || null;
    }
  }
  imageOrig = imageStyled ? imageStyled : imageOrig;
  if (imageOrig) {
    // Handle filenames with space(already encoded in Drupal),
    // like /salesforce/2023/05/2023-05-14%2018-a013Z000014Mg9BQAS.25.58-1.jpg
    parsed_url = imageOrig.substring(imageOrig.lastIndexOf('/')+1);
    let filename = parsed_url.substring(0, parsed_url.indexOf('.')+1);
    imageOrig = imageOrig.replace(filename, encodeURIComponent(filename));
  }
  if (!imageOrig && placeholder !== false) {
    imageOrig = placeholder || 'https://via.placeholder.com/1500x900?text=Placeholder+Image';
  }

  result = {imgAlt, imgTitle, imgWidth, imgHeight, imageOrig};
  return result;
};

export const taxonomyHierarchyArray = (data) => {
  let parents = [], children = [];
  data.map((res) => {
    let t = {
      internalId: res.internalId,
      id: res.id,
      name: res.name,
      path: res.alias,
    };
    if (res?.parent.length == 0) {
      parents.push(t);
    }
    else if (res?.parent.length > 0) {
      t['parent'] = res?.parent[0].internalId;
      children.push(t);
    }
  });

  parents.map((p, i) => {
    let child = children.filter(c => c.parent == p.internalId);
    parents[i]['children'] = child;
  });

  return parents;
}
