import Link from 'next/link';
import Box from '@material-ui/core/Box';
import Head from "next/head";
import Typography from "@material-ui/core/Typography";

export default function Custom404() {
  return (
    <>
      <Head>
        <title>Page not found | Pot-Nets</title>
        <meta name="description" content="This page content is not available any longer."/>
        <meta name={`robots`} content={`nofollow, noindex, noarchive, nosnippet, noodp, noydir, noimageindex, notranslate`}/>
      </Head>
      <Box my={5}>
        <Typography variant="h1" className={`page-title`}>Oops... You got lost!</Typography>
        <br/><br/>
        <Typography variant="h6">We may have packed this page!..</Typography>
        <br/><br/>
        <Typography variant="h6">Use the navigation above or
          &nbsp;<Link href="/contact-us">
            <a>Contact Us</a>
          </Link>&nbsp; and we'll help you find what you were looking for.</Typography>
      </Box>
    </>
  );
}
