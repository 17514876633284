import superAgent from 'superagent';
import superagentDefaults from 'superagent-defaults';
import superagentJsonapify from 'superagent-jsonapify';
import superagentPrefix from 'superagent-prefix';

// Get superagent object & make it ready to set some default values.
const superagent = (pref = 'api', solr = false, auth = false) => {
  let prefix = false;

  // Prepare prefix for each backend request to route to Contenta's backend.
  if (pref == 'api') {
    prefix = superagentPrefix(process.env.NEXT_PUBLIC_BACKEND_URL + process.env.NEXT_PUBLIC_JSONAPI_PREFIX);
  }
  else if (pref == 'decouple') {
    prefix = superagentPrefix(process.env.NEXT_PUBLIC_BACKEND_URL + process.env.NEXT_PUBLIC_DECOUPLED_PREFIX);
  }
  else if (pref == 'tld') {
    prefix = superagentPrefix(process.env.NEXT_PUBLIC_BACKEND_URL);
  }
  // else if (pref == 'solr') {
  //   prefix = superagentPrefix(process.env.NEXT_PUBLIC_FRONT_URL);
  // }
  const sup = superagentDefaults(superagentJsonapify(superAgent));

  if (pref && prefix) {
    sup.use(prefix);
  }

  // Pre-define superagent client.
  if (solr) {
    sup
      // Set the right URL prefix so that the request always
      // gets to the right place despite of being executed on
      // the server or client level.
      // JsonAPI Consumer headers for JSON API integration in Drupal.
      // .set('Authorization', 'Basic ' + Buffer.from(process.env.NEXT_PUBLIC_SOLR_USR + ':' + process.env.NEXT_PUBLIC_SOLR_PASS).toString('base64'))
      // .withCredentials()
      .set('Accept', 'application/json');
  }
  else {
    sup
      // Set the right URL prefix so that the request always
      // gets to the right place despite of being executed on
      // the server or client level.
      // JsonAPI Consumer headers for JSON API integration in Drupal.
      .set('X-Consumer-ID', process.env.CLIENT_ID)
      // Default headers for JSON API integration in Drupal.
      .set('Content-Type', 'application/vnd.api+json')
      .set('Accept', 'application/vnd.api+json, application/json');
  }

  return (sup);
};

export default superagent;
