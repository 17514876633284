import React, {Fragment} from 'react';
import { Box, Container, Grid, Link, List, ListItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SocialAccountsPotnets from '@/components/01_atoms/SocialAccounts/SocialAccountsPotnets'
import Logo from '@/components/01_atoms/Logo/Logo'

const useStyles = makeStyles((theme) => ({
  topGrid: {
    [theme.breakpoints.down("sm")]: {
      margin: '1rem 0'
    }
  },
  boxCenter: {
    [theme.breakpoints.down("sm")]: {
      alignItems: 'center',
      padding: 0,
      marginLeft: 0,
    }
  },
  bodyLogo: {
    '& div': {
      margin: 0,
      display: 'flex',
      justifyContent: 'center',
      "& img": {
        padding: '.5rem 1rem 1rem 1rem',
      }
    }
  },
  topLogo: {
    '& div': {
      margin: 0,
    },
  },
  footerTop: {
    padding: '1rem 0',
    '& h6': {
      color: 'white',
      textAlign: 'center',
      fontSize: 26,
      fontWeight: 700,
      '& span': {
        fontFamily: 'Lato',
        fontWeight: 400,
        fontSize: 22,
      },
    },
    '& .MuiGrid-item': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& img': {
        padding: 0,
      }
    }
  },
  footerRight: {
    '& .SocialaaWrapper': {
      '& a': {
        marginBottom: 0,
      },
      '& svg': {
        color: '#fff',
      }
    },
  },
  footerCopyright: {
    color: '#fff',
    fontSize: 16,
  },
  footerDisclaimer: {
    fontSize: 16,
    [theme.breakpoints.down("sm")]: {
      paddingRight: '3rem',
      paddingLeft: '3rem',
    },
  },
  footerMenuContent: {
    fontSize: 16,

    [theme.breakpoints.down("sm")]: {
      textAlign: 'center'
    },

  },
  footerMenuGrid: {
    marginLeft: '1rem',
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
  footerMenuHeader: {
    fontSize: 18,
    fontFamily: 'Lato',
    [theme.breakpoints.down("sm")]: {
      textAlign: 'center'
    },

  },
  footerContainer: {
    padding: 0,
  },
  footerGrid: {
    justifyContent: 'space-between',
  },
  footerList: {
    padding: 0,
    '& li': {
      [theme.breakpoints.down("sm")]: {
        alignItems: 'center',
        justifyContent: 'center'
      },
      padding: 0,
      '& a': {
        color: '#fff',
        marginBottom: '.5rem',
        fontFamily: 'Lato',
      }
    }
  },
  footerContact: {
    fontSize: 16,
    color: '#fff',
    fontFamily: 'Lato',
  },
  footerAddress: {
    fontSize: 16,
    color: '#fff',
    fontFamily: 'Lato',
  }
}),
);

export default function FooterDefault(props) {
  const { menu } = props;
  const classes = useStyles();
  const logoReverse = '/assets/FooterLogo.svg';

  const main_menu = (
      <>
        {menu && menu['#items'] && (
            <>
              {
                Object.entries(menu['#items']).map(([key, item]) => {
                  let inner_lvl1 = [], inner_lvl2 = [], uniqueID1;
                  let uniqueID = key.substring(key.length - 7);

                  inner_lvl2 = [];
                  if (item.below && (item.below.length > 0 || Object.keys(item.below).length)) {
                    for (const [key1, item1] of Object.entries(item.below)) {
                      let parent = null;
                      uniqueID1 = key1.substring(key1.length - 7);
                      if (key1.includes('menu_link_content:')) {
                        parent = {
                          title: item1.title,
                          href: item1.url,
                          key: uniqueID1,
                        };

                        if (item1.below && (item1.below.length > 0 || Object.keys(item1.below).length)) {
                          for (const [key2, item2] of Object.entries(item1.below)) {
                            if (key2.includes('menu_link_content:')) {
                              let uniqueID2 = key2.substring(key2.length - 7);
                              inner_lvl2.push({
                                title: item2.title,
                                href: item2.url,
                                key: uniqueID2,
                              });
                            }
                          }
                          parent['below'] = inner_lvl2;
                        }
                        inner_lvl1.push(parent);
                        inner_lvl2 = [];
                      }
                    }
                  }

                  return (
                      <Grid item xs={12} md={2} key={`acc-${uniqueID}`}
                            className={classes.footerMenuGrid}>
                        <Box mt={4} color='#fff' display='flex'
                             flexDirection='column' className={classes.boxCenter}>
                          {
                            inner_lvl1.map((item11, key11) => {
                              return (
                                  <Fragment key={`f-${key11}`}>
                                    <Typography className={classes.footerMenuHeader} key={`par-${key11}`}>
                                      <strong>
                                        {item11?.title}
                                      </strong>
                                    </Typography>
                                    <List className={classes.footerList} key={`list-${key11}`}>
                                      {item11?.below?.map((item12, key12) => {
                                        return (
                                            <ListItem key={key12}>
                                              <Link href={item12.href}
                                                    title={item12.title}>{item12.title}</Link>
                                            </ListItem>
                                        )
                                      })
                                      }
                                    </List>
                                  </Fragment>
                              )
                            })
                          }
                        </Box>
                      </Grid>
                  );
                  inner_lvl1 = [];
                })
              }
            </>
        )}
      </>
  );

  return (
      <Box display="block" displayPrint="none">

        <footer id="site-footer">
          <Box bgcolor="primary.main" px={1} pb={4}>
            <Container className={classes.footerContainer}>
              <Grid container className={classes.footerGrid}>
                <Grid item xs={12} md={2}>
                  <Box mt={4}>
                    <Box className={classes.bodyLogo}>
                      <Logo width={150} height={51} alt='Pot-Nets.' name={logoReverse} />
                    </Box>
                  </Box>
                  <Typography align='center' component='p' className={classes.footerAddress}>
                    34026 Anna's Way
                    <br />
                    Long Neck, DE 19966
                  </Typography>
                  <Typography align='center' component='p' className={classes.footerContact}>
                    Main Office: (302) 945-9300
                    <br />
                    Home Sales: (888) 534-6637
                  </Typography>
                </Grid>
                {main_menu}
                <Grid item xs={12} md={3} className={classes.footerMenuGrid}>
                  <Box mt={4} color='#fff' className={classes.footerRight}>
                    <SocialAccountsPotnets></SocialAccountsPotnets>
                    <Typography align='center' component='p' className={classes.footerCopyright}>
                      © {new Date().getFullYear()} Pot-Nets | All Rights Reserved
                    </Typography>
                    <Typography align='center' component='p' className={classes.footerDisclaimer}>
                      Prices, details, dimensions, and specifications displayed or shown on floor plans and home elevations may be approximate and are subject to change without notice. Prospective purchasers should verify the information to their own satisfaction. Information contained herein is believed to be reliable but is not guaranteed, including but not limited to Google Map locations. Please contact us for any questions you may have about this listing.
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </footer>
      </Box>
  );
}
