import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import theme from '@/theme_potnets';
import FacebookIcon from '@material-ui/icons/Facebook';
import PinterestIcon from '@material-ui/icons/Pinterest';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  socialLink: {
    margin: '.5rem 0 0 .5rem',
    borderRadius: '100%',
    height: 30,
    width: 40,
    display: 'inline-block',
    "& svg": {
      color: 'inherit',
    },
    "&:hover svg": {
      color: '#5FCFE9',
    },
    "& span": {
      color: theme.palette.grey[100],
    },
  }
});

export default function SocialAccountsPotnets(props) {
  const classes = useStyles();
  return (
    <div>
        <Grid container direction="row" justifyContent="center">
          <Grid item>
            <Link target="_blank" className={classes.socialLink} href="https://www.youtube.com/channel/UCDVcqlI9MVpbYvIbBSliOfw" rel="noopener">
              <YouTubeIcon />
              <Typography variant="srOnly">Connect Pot-Nets on YouTube</Typography>
            </Link>

            <Link target="_blank" className={classes.socialLink} href="https://www.facebook.com/potnets" rel="noopener">
              <Typography variant="srOnly" >Follow Pot-Nets on Facebook</Typography>
              <FacebookIcon />
            </Link>

            <Link target="_blank" className={classes.socialLink} href="https://www.pinterest.com/potnetsde/" rel="noopener">
              <PinterestIcon />
              <Typography variant="srOnly" >Follow Pot-Nets on Pinterest</Typography>
            </Link>

            <Link target="_blank" className={classes.socialLink} href="https://instagram.com/pot_nets/" rel="noopener">
              <InstagramIcon />
              <Typography variant="srOnly" >Connect with Pot-Nets on Instagram</Typography>
            </Link>
          </Grid>
        </Grid>
    </div>
  );
}
