import {useEffect, useRef, useState} from 'react';
import AppBar from '@material-ui/core/AppBar';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import Logo from '@/components/01_atoms/Logo/Logo';
import MenuIcon from '@material-ui/icons/Menu';
import ListItem from '@material-ui/core/ListItem';
import {makeStyles, createStyles, withStyles} from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Toolbar from '@material-ui/core/Toolbar';
import Popper from '@material-ui/core/Popper';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => createStyles({
  root: {
    display: 'flex',
  },
  textCenter: {
    textAlign: 'center',
  },

  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: theme.palette.grey[100],
    minHeight: 130,
    display: 'inline-block',
    zIndex: 100,
    "& p": {
      color: theme.palette.grey[700],
    }
  },

  utilityMenu: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    fontSize: 16,
    [theme.breakpoints.down("sm")]: {
      justifyContent: 'center',

    },
    "& li ": {
      padding: 0,
      width: 'auto',
    },
    "& a ": {
      color: theme.palette.grey[900],
      padding: '0 .75rem',
      display: 'flex',
      alignItems: 'inherit',
      fontWeight: 700,
      lineHeight: 2,


      [theme.breakpoints.down("sm")]: {
        fontSize: '.75rem',
        padding: '0 .5rem',
      },

      '&:hover': {
        color: theme.palette.primary,
      },


    },
    "& li:last-child a": {
      paddingRight: 0,
    },
    "& svg ": {
      width: 24,
      height: 24,
      margin: '0 .5rem 0 0',
      [theme.breakpoints.down("sm")]: {
        width: 16,
        margin: '0 .25rem 0 0',

      },
    },
  },
  "& .residentLogin svg": {
    width: 18,
    height: 18,
  },
  mainMenuAccordions: {
    display: 'flex',
    background: 'transparent',
    border: 0,
    justifyContent: 'center',
    marginLeft: 'auto',
    [theme.breakpoints.down("sm")]: {
      flexDirection: 'column',
      justifyContent: 'start',

    },
    "& .MuiAccordionSummary-root:hover a, & .MuiAccordionSummary-root:hover span": {
      color: theme.palette.primary.main,
    },
    "& .MuiAccordion-root": {
      position: 'relative',
      margin: '0 !important',
      border: 'none',
      [theme.breakpoints.up('md')]: {
        paddingTop: '1.75rem',
      },
      "& li": {
        padding: 0,
        width: '100%',
        display: 'block',
      },

      "&  span, & a": {
        display: 'block',
        color: theme.palette.grey[900],
        fontFamily: 'Playfair Display',
        fontSize: 18,
        fontWeight: 700,
        padding: '0 1rem',
        textTransform: 'uppercase',
        [theme.breakpoints.down('sm')]: {
          fontSize: 20,
        },
        [theme.breakpoints.up('md')]: {
          fontSize: 16,
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: 20,
        },

      },
      "& svg": {
        width: '.75rem',
      },
      "& .Mui-expanded span": {

        color: theme.palette.primary.main,
      },
      "& a": {
        padding: '.5rem 1rem',
      },
      "& ul a": {
        textTransform: 'none',
      },
      "& li li a": {
        fontWeight: 400,
      },
    },
    "& .MuiAccordionDetails-root": {
      background: 'white',
      border: 0,
      boxShadow: '0px 9px 9px rgb(0 0 0 / 5%)',
      [theme.breakpoints.up("md")]: {
        position: 'absolute',
        minWidth: 300,
        left: '1rem',
        top: '5rem',
        padding: '0',
      },
      "& ul": {
        width: '100%',
      },
      "& li li a": {
        paddingLeft: '1.75rem !important',
      },
      "& a": {
        display: 'block',
        padding: '.2rem 0',
        fontSize: '18px !important',
      },
      "& a:hover": {
        backgroundColor: theme.palette.grey[200],
        textDecoration: 'none',
      }
    },
    "& svg": {
      transform: 'rotate(0deg)',
    },
    "& .Mui-expanded svg": {
      transform: 'rotate(180deg)',
    },

  },
  ctaContainer: {
    textAlign: 'right',
  },
  headerCta: {
    marginTop: '.75rem',
    marginLeft: 'auto',
    fontSize: '1rem !important',
    padding: '1.125rem 1.5rem !important',
    [theme.breakpoints.up("lg")]: {
      fontSize: '1.25rem !important',
      padding: '1.125rem 2.75rem !important',

    },
  },
  headerCtaDesktop: {
    marginTop: '1.5rem',
    marginLeft: '1rem',
    fontSize: '.745rem !important',
    padding: '1.125rem 1.75rem !important',
    [theme.breakpoints.up("lg")]: {
      fontSize: '1.25rem !important',
      padding: '1.125rem 2.75rem !important',

    },
  },
  mainMenuItem: {
    padding: '0 !important',
    "& span": {
      display: "block",
      marginBottom: 0,
    }
  },
  hideOnMobile: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  logoContainer: {
    '& img': {
      [theme.breakpoints.down('sm')]: {
        maxWidth: 200,
        width: 200,
      },
    }
  },
  iconStyles: {
    marginLeft: 'auto',
    '& svg': {
      height: '2em',
      width: '2em'
    }
  },
  popoverStyles: {
    transform: 'translate3d(0px, 169px, 0px) !important',
    width: '100%',
    zIndex: 100,
    [theme.breakpoints.down("sm")]: {
      transform: 'translate3d(0px, 169px, 0px) !important',
    },
  }
}));
const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);
const AccordionSummary = withStyles({
  root: {
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    alignItems: 'center',
    justifyContent: 'space-between',
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);
const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

export default function TemporaryDrawer(props) {
  const { menu } = props;
  const classes = useStyles();
  const logo = '/assets/baywood-greens-logo.svg';

  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const main_menu = (
    <Box displayPrint="none">
      {menu && menu['#items'] && (
        <Box className={classes.mainMenuAccordions}>
          {
            Object.entries(menu['#items']).map(([key, item]) => {
              let inner_lvl1 = [], inner_lvl2 = [], uniqueID1;
              let uniqueID = key.substring(key.length - 7);

              inner_lvl2 = [];
              if (item.below && (item.below.length > 0 || Object.keys(item.below).length)) {
                for (const [key1, item1] of Object.entries(item.below)) {
                  let parent = null;
                  uniqueID1 = key1.substring(key1.length - 7);
                  if (key1.includes('menu_link_content:')) {
                    parent = {
                      title: item1.title,
                      href: item1.url,
                      key: uniqueID1,
                    };

                    if (item1.below && (item1.below.length > 0 || Object.keys(item1.below).length)) {
                      for (const [key2, item2] of Object.entries(item1.below)) {
                        if (key2.includes('menu_link_content:')) {
                          let uniqueID2 = key1.substring(key2.length - 7);
                          inner_lvl2.push({
                            title: item2.title,
                            href: item2.url,
                            key: uniqueID2,
                          });
                        }
                      }
                      parent['below'] = inner_lvl2;
                    }
                    inner_lvl1.push(parent);
                    inner_lvl2 = [];
                  }
                }
              }

              return (
                <Accordion key={`menu-exp-${key}`} expanded={expanded === `nav-${uniqueID}`} key={`acc-${uniqueID}`} onChange={handleChange(`nav-${uniqueID}`)}>
                  <AccordionSummary aria-controls={`${uniqueID}-content`} id={`${uniqueID}-header`}>
                    <span>{item.title}</span>
                    <svg width="22" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path opacity="0.5" fillRule="evenodd" clipRule="evenodd" d="M2.56667 0.26683L1.31174e-07 2.8335L11 13.8335L22 2.8335L19.4333 0.26683L11 8.70016L2.56667 0.26683Z" fill="#C4C4C4" />
                    </svg>
                  </AccordionSummary>
                  <AccordionDetails>
                    {inner_lvl1.length > 0 &&
                      <List>
                        {inner_lvl1.map((item11, key11) => {
                          return (
                            <ListItem key={key11}>
                              <Link href={item11.href} title={item11.title}>{item11.title}</Link>
                              {(item11['below'])?.length > 0 &&
                                <List>
                                  {
                                    item11['below'].map((item12, key12) => {
                                      return (
                                        <ListItem key={key12}>
                                          <Link href={item12.href} title={item12.title}>{item12.title}</Link>
                                        </ListItem>
                                      )
                                    })
                                  }
                                </List>
                              }
                            </ListItem>
                          )
                        })
                        }
                      </List>
                    }
                  </AccordionDetails>
                </Accordion>
              );
              inner_lvl1 = [];
            })
          }
        </Box>
      )}
    </Box>
  );

  return (
    <Container displayPrint="none">
      <div className={classes.root} >
        <CssBaseline />
        <AppBar elevation={0} position="relative" className={clsx(classes.appBar)}>

          <Grid item xs={12}>
            <Box displayPrint="none">
              <List className={classes.utilityMenu}>
                <ListItem key={`utility1`}>
                  <Link href='https://jobs.teamengine.io/potnets' target={`_blank`}>
                    <span>CAREERS</span></Link>
                </ListItem>
                <ListItem key={`utility2`}>
                  <Link href='/golf/tee-times'>
                    <svg width='24' height='24' viewBox='0 0 24 24' fill='none'
                      xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M16.2803 12.2197C16.5732 12.5126 16.5732 12.9874 16.2803 13.2803L11.7803 17.7803C11.6397 17.921 11.4489 18 11.25 18C11.0511 18 10.8603 17.921 10.7197 17.7803L8.46967 15.5303C8.17678 15.2374 8.17678 14.7626 8.46967 14.4697C8.76256 14.1768 9.23744 14.1768 9.53033 14.4697L11.25 16.1893L15.2197 12.2197C15.5126 11.9268 15.9874 11.9268 16.2803 12.2197Z'
                        fill='#C4C4C4' />
                      <path
                        d='M5.25 0C5.66421 0 6 0.335786 6 0.75V1.5H18V0.75C18 0.335786 18.3358 0 18.75 0C19.1642 0 19.5 0.335786 19.5 0.75V1.5H21C22.6569 1.5 24 2.84315 24 4.5V21C24 22.6569 22.6569 24 21 24H3C1.34315 24 0 22.6569 0 21V4.5C0 2.84315 1.34315 1.5 3 1.5H4.5V0.75C4.5 0.335786 4.83579 0 5.25 0ZM3 3C2.17157 3 1.5 3.67157 1.5 4.5V21C1.5 21.8284 2.17157 22.5 3 22.5H21C21.8284 22.5 22.5 21.8284 22.5 21V4.5C22.5 3.67157 21.8284 3 21 3H3Z'
                        fill='#C4C4C4' />
                      <path
                        d='M3.75 6C3.75 5.58579 4.08579 5.25 4.5 5.25H19.5C19.9142 5.25 20.25 5.58579 20.25 6V7.5C20.25 7.91421 19.9142 8.25 19.5 8.25H4.5C4.08579 8.25 3.75 7.91421 3.75 7.5V6Z'
                        fill='#C4C4C4' />
                    </svg>
                    <span>BOOK A TEE-TIME</span></Link>
                </ListItem>
                <ListItem key={`utility3`} className={classes.hideOnMobile}>
                  <Link href='/contact'>
                    <svg width='24' height='24' viewBox='0 0 24 24' fill='none'
                      xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M21 1.5C21.8284 1.5 22.5 2.17157 22.5 3V15C22.5 15.8284 21.8284 16.5 21 16.5H17.25C16.3057 16.5 15.4166 16.9446 14.85 17.7L12 21.5L9.15 17.7C8.58344 16.9446 7.69427 16.5 6.75 16.5H3C2.17157 16.5 1.5 15.8284 1.5 15V3C1.5 2.17157 2.17157 1.5 3 1.5H21ZM3 0C1.34315 0 0 1.34315 0 3V15C0 16.6569 1.34315 18 3 18H6.75C7.22214 18 7.66672 18.2223 7.95 18.6L10.8 22.4C11.4 23.2 12.6 23.2 13.2 22.4L16.05 18.6C16.3333 18.2223 16.7779 18 17.25 18H21C22.6569 18 24 16.6569 24 15V3C24 1.34315 22.6569 0 21 0H3Z'
                        fill='#C4C4C4' />
                      <path
                        d='M4.5 5.25C4.5 4.83579 4.83579 4.5 5.25 4.5H18.75C19.1642 4.5 19.5 4.83579 19.5 5.25C19.5 5.66421 19.1642 6 18.75 6H5.25C4.83579 6 4.5 5.66421 4.5 5.25ZM4.5 9C4.5 8.58579 4.83579 8.25 5.25 8.25H18.75C19.1642 8.25 19.5 8.58579 19.5 9C19.5 9.41421 19.1642 9.75 18.75 9.75H5.25C4.83579 9.75 4.5 9.41421 4.5 9ZM4.5 12.75C4.5 12.3358 4.83579 12 5.25 12H12.75C13.1642 12 13.5 12.3358 13.5 12.75C13.5 13.1642 13.1642 13.5 12.75 13.5H5.25C4.83579 13.5 4.5 13.1642 4.5 12.75Z'
                        fill='#C4C4C4' />
                    </svg>
                    <span>CONTACT US</span></Link>
                </ListItem>
                <ListItem key={`utility4`}>
                  <Link href='https://payments.tunnellcompanies.com/customerPortal/login.aspx?ReturnUrl=%2fcustomerPortal%2f' rel="noopener" target="_blank">
                    <svg width='18' height='18' viewBox='0 0 22 22' fill='none'
                      xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M11 0C12.4587 0 13.8576 0.579462 14.8891 1.61091C15.9205 2.64236 16.5 4.04131 16.5 5.5C16.5 6.95869 15.9205 8.35764 14.8891 9.38909C13.8576 10.4205 12.4587 11 11 11C9.54131 11 8.14236 10.4205 7.11091 9.38909C6.07946 8.35764 5.5 6.95869 5.5 5.5C5.5 4.04131 6.07946 2.64236 7.11091 1.61091C8.14236 0.579462 9.54131 0 11 0ZM11 13.75C17.0775 13.75 22 16.2113 22 19.25V22H0V19.25C0 16.2113 4.9225 13.75 11 13.75Z'
                        fill='#C4C4C4' />
                    </svg>
                    <span>RESIDENT LOGIN</span></Link>
                </ListItem>
              </List>
            </Box>
          </Grid>
          <Toolbar disableGutters className={classes.logoContainer}>
            <Logo width={150} height={122} alt="Baywood Greens" name={logo}></Logo>

              <Hidden smDown>
                {main_menu}
                <Hidden smUp>
                  <Button className={classes.headerCta} size="large" href="/living/homes/pre-owned"
                    disableElevation variant="contained" color="primary">
                    FIND A HOME
                  </Button>
                </Hidden>
                <Hidden smDown>
                  <Box displayPrint="none">
                  <Button  className={classes.headerCtaDesktop} size="large" href="/living/homes/pre-owned"
                    disableElevation variant="contained" color="primary">
                    FIND A HOME
                  </Button>
                  </Box>
                </Hidden>
              </Hidden>
              <Hidden mdUp>
                <Box displayPrint="none">
                <IconButton
                  className={classes.iconStyles}
                  ref={anchorRef}
                  aria-controls={open ? 'menu-list-grow' : undefined}
                  aria-haspopup="true"
                  onClick={handleToggle}>
                  <MenuIcon />
                </IconButton>
                </Box>
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} className={classes.popoverStyles}>
                  {main_menu}
                </Popper>
              </Hidden>
          </Toolbar>
        </AppBar>
      </div>
    </Container>
  );
}
