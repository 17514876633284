import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Custom404Potnets from '@/pages/404';
import Custom403Potnets from '@/pages/403';
import {useContext} from "react";
import CurrentPageContext from "@/components/context/CurrentPageData";

function ErrorMessage({statusCode}) {
  const sitewideCtx = useContext(CurrentPageContext); // sitewideCtx?.site can be "baywoods" or "potnets"

  return (
    <Container>
      <Box className="error-message" textAlign="center" py={10}>
        {statusCode == 404 && sitewideCtx?.site == 'potnets' &&
          <Custom404Potnets/>
        }
        {statusCode == 403 && sitewideCtx?.site == 'potnets' &&
          <Custom403Potnets/>
        }
        {statusCode != 404 && statusCode != 403 &&
        <>
          <Typography variant="h1" component="h1">
            Oops, <strong>{statusCode}</strong> error.
          </Typography>
          <Box className="error-message" textAlign="center" py={6}>
          </Box>
        </>
        }
      </Box>
    </Container>
  )
}

ErrorMessage.propTypes = {
  statusCode: PropTypes.number.isRequired,
};

export default ErrorMessage;
