import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Head from "next/head";

export default function Custom403() {
  return (
    <>
      <Head>
        <title>Access Denied | Pot-Nets</title>
        <meta name="description" content="This page seems to have protected content. You don't have enough permissions to view this page."/>
        <meta name={`robots`} content={`nofollow, noindex, noarchive, nosnippet, noodp, noydir, noimageindex, notranslate`}/>
      </Head>
      <Box my={5}>
        <Typography variant="h1" className={`page-title`}>Access Denied</Typography>
        <br/><br/>
        <Typography style={{fontSize: "2rem",}} component="p">This page is no longer available, or you don't have enough access to view it. <br /> But don't turn back, we'll find a great fit for you.<br /> Check out our <a href="/homes">Homes for Sale</a> or <a href="/rentals">Homes for Rent</a> pages.</Typography><br/><br/>
      </Box>
    </>
  );
}
