import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Link from 'next/link';
import Image from 'next/image';
const useStyles = makeStyles({
  logoWrapper: {
    marginRight: 'auto',
    [`@media only print`]: {
      marginLeft: 'auto',
      marginBottom: '1rem',
    },
    backgroundColor: props => props.reverseLogo
      ? '#555'
      : 'transparent',
    "& img": {
      paddingBottom: '1rem',
      [`@media (max-width: 768px)`]: {
        width: 156,
      },
      maxWidth: '156px',
      width: 150,
    },
  },
});

export default function Logo(props) {
  const classes = useStyles(props);
  return (
    <div className={classes.logoWrapper}>
      <a href="/">
        <Image width={props.width} height={props.height} src={props.name} alt={props.alt}/>
        </a>
    </div>
  );
}