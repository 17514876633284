import {
  createTheme
} from '@material-ui/core/styles';

const defaultTheme = createTheme();

const theme = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1400,
      xl: 1600
    },
  },
  name: 'PotNets',
  themeName: 'PotNets',
  palette: {
    statusActive: '#026e39',
    primary: {
      main: '#02606E',
      contrastText: '#ffffff',
      light: '#00B3DC',
    },
    secondary: {
      main: '#7E4D11',
      dark: '#AC9C81',
      light: '#F1EBDD',
      contrastText: '#ffffff',
    },
    text: {
      main: '#f44336',
      secondary: '#8D8D8D',
      disabled: '#333333',
    },
    error: {
      main: "#e26126",
      contrastText: "#000000",
    },
    success: {
      main: '#3C6943',
      light: '#42B5B5',
      lighter: '#FEF0D3',
    },
    warning: {
      main: '#E28C26',
      light: '#ffee88',
      dark: '#000000',
    },
    info: {
      main: '#326398',
      light: '#0071B3',
    },
    grey: {
      100: '#FFFFFF',
      200: '#F4F4F4',
      300: '#C4C4C4',
      700: '#3F3F3F',
      800: '#333333',
      900: '#000000',
    },
    tealBlue: {
      dark: '#006680',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    body: {
      fontSize: 4,
      color: '#f0f0f0',
    }
  },
  typography: {
    htmlFontSize: 16,
    fontFamily: "'khand', sans-serif",
    h1: {
      fontSize: "3.75rem",
      fontFamily: 'Luckiest Guy, Arial',
      textTransform: 'uppercase',
      fontWeight: '300 !important',

      color: '#02606E',
      [defaultTheme.breakpoints.down("sm")]: {
        fontSize: '2.75rem',
      },
    },
    h2: {
      fontSize: "2.5rem",
      fontFamily: 'Luckiest Guy, Arial',
      textTransform: 'uppercase',
      fontWeight: '300 !important',

      color: '#02606E',
      [defaultTheme.breakpoints.down("sm")]: {
        fontSize: '2.5rem',
      },
    },
    h3: {
      fontSize: "2.25rem",
      fontFamily: 'Luckiest Guy, Arial',
      textTransform: 'uppercase',
      fontWeight: '300 !important',

      color: '#02606E',
      [defaultTheme.breakpoints.down("sm")]: {
        fontSize: '2rem',
      },
    },
    h4: {
      fontSize: "1.75rem",
      fontFamily: 'Luckiest Guy, Arial',
      textTransform: 'uppercase',
      fontWeight: '300 !important',

      color: '#02606E',
      [defaultTheme.breakpoints.down("sm")]: {
        fontSize: '1.85rem',
      },
    },
    h5: {
      fontSize: "1.45rem",
      fontFamily: 'Luckiest Guy, Arial',
      textTransform: 'uppercase',
      color: '#02606E',
      fontWeight: '300 !important',
      [defaultTheme.breakpoints.down("sm")]: {
        fontSize: '1.75rem',
      },
    },
    h6: {
      fontSize: "1.125rem",
      fontFamily: 'Luckiest Guy, Arial',
      textTransform: 'uppercase',
      color: '#02606E',
    },
    subtitle1: {
      fontSize: "1.125rem",
      textTransform: 'uppercase',
      color: '#02606E',
      fontWeight: 300,
    },
    subtitle2: {
      fontSize: "1rem",
      textTransform: 'uppercase',
      color: '#02606E',
      fontWeight: 300,
    },
    body1: {
      fontSize: "1.5rem",
      marginBottom: "1rem",
    },
    body2: {
      fontSize: "1.25rem",
    },
  },
  shape: {
    borderRadius: 0,
  },
  overrides: {
    MuiPagination: {
      ul: {
        justifyContent: 'center',
        // justifyContent: 'flex-start !important',
        [defaultTheme.breakpoints.down("sm")]: {
          justifyContent: 'space-between !important',
        },
        "& li ": {
          listStyleType: 'none !important',
        },
      },
    },
    MuiPaginationItem: {
      root: {
        color: '#0071B3',
        fontSize: 24,
        selected: {
          backgroundColor: 'transparent !important',
        },
        "&$outlined": {
          border: 'none',
        },
        outlinedPrimary: {
          border: 'none !important',
          backgroundColor: 'transparent !important',
          '&:hover': {
            color: '#00235D',
          },
        },
        "&$selected": {
          "backgroundColor": "transparent !important",
          color: '#00235D !important',
          border: 'none !important',
        }
      },

    },
    textCenter: {
      textAlign: 'center',
    },
    MuiCssBaseline: {
      '@global': {
        body: {
          maxWidth: '100vw',
          overflowX: 'hidden',
          backgroundColor: '#ffffff',
          '&.homepage': {
            '& .para--img_banner:first-of-type': {
              '& .imgWrapper': {
                '&:after': {
                  content: "''",
                  width: '100%',
                  height: '100%',
                  background: 'url(/assets/waves.svg) no-repeat',
                  transform: 'rotate(180deg)',
                  position: 'absolute',
                  top: '10%',
                  left: 0,
                  zIndex: 1
                }
              }
            },
            backgroundColor: '#00B3DC !important',
            '& .para--cta_card': {
              '& h2': {
                color: '#000'
              },
              '& p': {
                color: '#000'
              }
            },
            '& .ctaWrapper': {
              '& .ctaContent': {
                transform: 'translateY(0) !important',
                [defaultTheme.breakpoints.up("lg")]: {
                  top: '65%',
                  left: 'unset',
                  right: '15%',
                  transform: 'translateY(-50%) !important',
                  '& .title': {
                    fontSize: '72px !important',
                  },
                  '& .subtitle': {
                    fontSize: '50px !important',
                  }
                },
                '& .title': {
                  margin: 0
                },
                '& .subtitle': {
                  fontWeight: '100 !important'
                }
              }
            }
          },
          '& .tenant-box': {
            '& .description p': {
              color: '#000',
              fontWeight: '200 !important',
              fontFamily: 'Khand',
              fontSize: 25
            },
            '& .tenant-name p': {
              color: '#000',
              '&:first-child': {
                fontSize: 25,
                fontWeight: '200 !important',
                fontFamily: 'Khand'
              },
            }
          },
          '& .about-banner.large-banner': {
            marginBottom: 0,
            '& .banner': {
              '& .title': {
                fontSize: '72px !important',
                [defaultTheme.breakpoints.down("sm")]: {
                  fontSize: '40px !important',
                },
              },
              '& .bannerButtonWrapper a': {
                fontSize: 22,
              },

            },
          },
          '&.region-top': {
            marginBottom: '0 !important',
          },
          '& .banner:not(.banner-sm)': {
            '& .title': {
              fontSize: '24px !important',
              fontWeight: '600 !important',
              marginBottom: 50,
              order: 1
            },
            '& .subtitle': {
              fontSize: '22px !important',
              fontFamily: 'Khand !important',
              fontWeight: '600 !important',
              order: 2,
            },
            '& .bannerButtonWrapper': {
              order: 3,
              '& .MuiGrid-item': {
                padding: '.5rem !important',
              },
            }
          },
          '& .banner.banner-sm': {
            height: 560,
            '& .title': {
              marginTop: 150
            },
          },
          "& hr": {
            width: '100%',
          },
          "& ul li": {
            listStyle: 'disc',
          },
          "& img": {
            maxWidth: '100%',
            height: 'auto',
          },
          "& .serif": {
            fontFamily: 'Luckiest Guy, Arial',
            textTransform: 'none',
          },
          "& h1,h2,h3,h4,h5,h6": {
            "& strong": {
              fontWeight: '300 !important',
            },
          },
          '& .banner-xl .title, &  .banner-xl .subtitle': {
            fontWeight: '300 !important',
            fontFamily: 'Luckiest Guy, Arial',
          },
          '& footer svg': {
            color: 'white !important',
            marginBottom: '2rem',
          },
          '& footer a:hover svg': {
            color: 'rgba(255,255,255,.75) !important',
          },
          '& .node-type-house_listing iframe': {
            margin: 0,
          },
          '& .node-type-house_listing svg': {
            fill: '#02606E',
          },
          '& .node-type-house_listing': {
            [defaultTheme.breakpoints.up("md")]: {
              paddingTop: 130,
            },
          },
          "& h1, & h2, & h3, & h4, & h5, & h6": {
            fontFamily: 'Luckiest Guy, Arial',
          },
          "& .card-link svg": {
            fill: '#02606E',
          },
          "& .card-content": {
            backgroundColor: '#F1EBDD',
            padding: '.5rem',
          },
          "& .btn": {
            backgroundColor: '#02606E',
            padding: '12px 16px 6px',
            fontSize: '22px',
            color: 'white',
            textTransform: 'uppercase',
            display: 'inline-block',
            textDecoration: 'none',
            fontWeight: 400,
            transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            borderRadius: '999px',
            fontFamily: 'Luckiest Guy, Arial',
          },
          "& .btn:hover": {
            backgroundColor: '#01434d',
          },
          "& .btn.sm": {
            fontSize: 20,
            padding: '12px 12px 6px',
          },
          "& .btn.lg": {
            padding: '14px 16px 6px',
            fontSize: '28px',
            lineHeight: '1.2',
          },
          "& .lato": {
            fontFamily: "'Lato', sans-serif !important",
          },
          "& .khand": {
            fontFamily: "'Khand', sans-serif !important",
          },
          "& .wysiwyg-2-col, .wysiwyg-3-col": {
            display: 'flex',
            margin: '1rem 0',
            flexDirection: 'column',
            "@media (min-width: 1025px)": {
              flexDirection: 'row',
            },
          },
          "& .wysiwyg-2-col .col": {
            width: '100%',
            "@media (min-width: 1025px)": {
              width: '50%',
            },
          },
          "& .wysiwyg-3-col .col": {
            width: '100%',
            "@media (min-width: 1025px)": {
              width: '33%',
            },
          },
          "& .wysiwyg-block.bg-color-gray": {
            backgroundColor: '#F8F8F8',
            padding: '1rem',
          },
          "& .wysiwyg-block.bg-color-tan": {
            backgroundColor: '#F1EBDD',
            padding: '1rem',
          },
          "& .wysiwyg-block.bg-color-dark-gray": {
            backgroundColor: '#212529',
            padding: '1rem',
            color: 'white',
          },
          "& .wysiwyg-block.bg-color-dark-gray *": {
            color: 'white',
          },
          "& .wysiwyg-icon-col": {
            display: 'flex',
            alignItems: 'center',
            margin: '1rem 0',
          },
          "& .wysiwyg-icon-col .col:first-child": {
            minWidth: '50px',
          },
          "& .wysiwyg-icon-col .col + .col": {
            marginLeft: '2rem',
            lineHeight: '1.2',
          },
          "& .ctaWrapper": {
            backgroundColor: 'black',
          },
          '& .formCtaWrapper': {
            backgroundColor: '#F1EBDD',
          },
          "& .breadcrumb-list": {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'start',
            "& li": {
              width: 'auto',
              fontSize: '1rem',
              padding: '0 0.3rem',
              "&:not(:last-child):after": {
                content: "' /'",
                paddingLeft: ".7rem",
              },
              "& a": {
                fontSize: '1rem',
              }
            }
          },
          "& .testimonial-carousel > div > div > div": {
            maxHeight: 2000,
          },
          "& .node-type-house_listing .slick-slider .slick-track": {
            [defaultTheme.breakpoints.down("sm")]: {
              maxHeight: '400px',
            },
          },
        },

        code: {
          backgroundColor: "#f1f1f1",
          marginTop: '1rem',
          padding: '1rem',
          fontFamily: "'khand', sans-serif",
          display: 'block',
        },
        khand: {
          fontFamily: "'khand', sans-serif",
        },
        '.box-shadow': {
          boxShadow: '0 0 1px 1px white inset, 0 23px 21px -33px #000',
          backgroundColor: 'white !important',
          padding: '1rem 1rem 0',
          margin: '2.1rem 0',
          border: 'solid thin black',
        },
        '.responsive-iframe-container iframe': {
          [defaultTheme.breakpoints.down("md")]: {

            width: '100% !important',
            height: 'auto',
          },
        },

        '.responsive-iframe-container': {
          [defaultTheme.breakpoints.down("md")]: {
            position: 'relative',
            overflow: 'hidden',
            width: '100%',
            paddingTop: '56.25%',
            '& iframe': {
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              width: '100%',
              height: '100%',
            },
          },
        },
        ".content-css span, .content-css p": {
          fontSize: 20,
        },
        ".expandAnchor": {
          fontSize: 12,
          textDecoration: 'underline',
        },
        ".cardContents": {
          backgroundColor: '#f1ebde',
        },
      },
    },
    // Style sheet name ⚛️
    MuiTabs: {
      indicator: {
        backgroundColor: 'transparent',
      }
    },
    MuiButton: {
      contained: {
        backgroundColor: "#333333",
        color: 'white',
        borderRadius: '999px',
        fontWeight: '300 !important',
        fontFamily: 'Khand, Arial',
        "&:hover": {
          backgroundColor: "#111111",
        }
      },
      root: {
        fontSize: 24,
        padding: '12px 16px 6px',
        fontFamily: 'Luckiest Guy, Arial',
        lineHeight: '1.2',
        fontWeight: 300,
        textAlign: 'center',
        [defaultTheme.breakpoints.down("md")]: {
          fontSize: 18,
          margin: 0,
        },
      },
      sizeSmall: {
        fontSize: 20,
        padding: '12px 12px 6px',
        fontWeight: 300,
      },
      // sizeMedium: {
      //   fontSize: '24px !important',
      //   padding: '6px 16px',
      // },
      sizeLarge: {
        fontSize: 28,
        padding: '14px 16px 6px ',
      },

    },
    MuiTab: {
      root: {
        fontSize: 18,
        marginRight: 8,
        [defaultTheme.breakpoints.up("md")]: {
          fontSize: 39,
          marginRight: 40,
          maxWidth: 1000,
        },
        whiteSpace: 'inherit',
        overflow: 'visible',
        fontWeight: 300,
        color: '#5A646F !important',
        opacity: '1 !important',
        paddingLeft: 0,
        "& :hover": {
          fontWeight: 600,
        },
        "& span": {
          alignItems: 'flex-start',
        },
        "&$selected": {
          fontWeight: 800,
          "& :hover": {
            fontWeight: 800,
          }
        },

      },
    },
    MuiBreadcrumbs: {
      root: {
        fontSize: 12,
        padding: '1rem 0',
      },
      li: {
        "& p": {
          fontSize: 12,
          display: 'inline',
        },

        "& a": {
          color: '#01606e',
        },
      },
    },
    MuiAccordion: {
      root: {
        // backgroundColor: '#f2f2f2 !important',
        backgroundColor: 'transparent',
      },
    },
    MuiAccordionSummary: {
      root: {
        '&.accordion-wrapper': {
          backgroundColor: '#f6f6f6'
        }
      },
      content: {
        "& div": {
          color: '#000000',
          textAlign: 'left',
          fontFamily: 'Luckiest Guy, Arial',
          fontSize: '28px',
          lineHeight: '30px',
          paddingTop: '.6rem',
          fontWeight: 300,
        },
      },
      expandIcon: {
        backgroundColor: '#01606e',
        borderRadius: 0,
        padding: '6px',
        margin: '0 0 0 10px',
        "& svg": {
          fill: 'white !important',
        },
        "&:hover": {
          backgroundColor: '#01606e',
        },
      },
    },
    MuiAccordionDetails: {
      root: {
        backgroundColor: '#f6f6f6',
        "& div": {
          "& div, & p": {
            fontSize: '22px !important',
            lineHeight: '21.6px !important',
          },
        },
        '& .amenities-row': {
          alignItems: 'center',
          '& .amenities-80-col': {
            flexBasis: '80%',
            marginBottom: 10
          },
          '& .amenities-20-col': {
            flexBasis: '20%'
          }
        }
      },
    },
  },
};

export const themeExtender = createTheme(theme);

export default theme;
