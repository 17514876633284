import React from 'react';
import {makeStyles, createStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {top: `${top}%`, left: `${left}%`, transform: `translate(-${top}%, -${left}%)`};
}

const useStyles = makeStyles((theme) => ({
  contentStyles: {
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      justifyContent: 'center'
    }
  },
  buttonStyles: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end'
    }
  },
  bodyStyles: {
    fontSize: '18px'
  },
  footerButton: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      margin: '2rem 0 3rem'
    }
  },
  paper: {
    position: 'absolute',
    width: 650,
    height: 650,
    overflowX: 'hidden',
    overflowY: 'auto',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  }
}),);

const FooterBanner = (props) => {
  let {
    title,
    body,
    url,
    buttonText,
    backgroundColor,
    color,
    formUrl,
    formHeight
  } = props;
  const classes = useStyles(props);
  const [open, setOpen] = React.useState(false);
  const [modalStyle] = React.useState(getModalStyle);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (<Box style={{
      backgroundColor
    }} py={3}>
    <Container >
      <Grid container alignItems='center' justifyContent='center'>
        <Grid md={6} className={classes.contentStyles} item>
          <Typography style={{
              color
            }} variant='h5'>{title}</Typography>
          <Typography style={{
              color
            }} className={classes.bodyStyles} variant='body1'>{body}</Typography>
        </Grid>
        <Grid md={6} className={classes.buttonStyles} item>
          <Button onClick={handleOpen} disableElevation variant='contained' color='primary' href={url}>
            {buttonText}
          </Button>
        </Grid>
      </Grid>
    </Container>

    <Modal open={open} onClose={handleClose} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
      <div style={modalStyle} className={classes.paper}>
        <a href="#" onClick={handleClose}><CloseIcon></CloseIcon><Typography variant='srOnly'>Close the modal window</Typography></a>
        <p id="simple-modal-description">
          <iframe frameBorder="0" width="600" height={'1000px'} className={classes.iframeStyles} src={formUrl} title={title || 'Form CTA'}></iframe>

        </p>
      </div>
    </Modal>

  </Box>);
};

export default FooterBanner;
