import {
  listOpenHouseStatus, listSaleStatus_3, listSaleStatus_1, listSaleStatus_2,
  listSaleStatus_4, listSaleStatus_5, listSaleStatus_6, listSaleStatus_7, listSaleStatus_8
} from '@/helpers/listing_data';

export const resetDefaultValuesBuy = {
  txt: '',
  type: 'Buy',
  prop_type: [0],
  price_min: 0,
  price_max: 5000000,
  city: ['all'],
  neighbor: ['all'],
  beds: 0,
  baths: 0,
  pool: false,
  waterfront: false,
  featured: false,
  available: false,
  openhouse: false,
  myfavorite: false,
  sort: [{dir: 'DESC', field: 'its_brokerage'}],
  page: 0,
};

export const resetDefaultValuesRent = {
  txt: '',
  type: 'Rental',
  prop_type: [0],
  price_min: 0,
  price_max: 10000,
  sqft_min: 0,
  sqft_max: 6000,
  city: ['all'],
  neighbor: ['all'],
  beds: 0,
  baths: 0,
  // garage_min: 0,
  // garage_max: 5,
  pool: false,
  waterfront: false,
  featured: false,
  available: false,
  openhouse: false,
  sort: [{dir: 'DESC', field: 'its_brokerage'}],
  // page: 0,
};

/**
 * Returns JSON API URL of entity.
 */
export const url = (rsc, id) => (`/${rsc}/${id}`);

/**
 * Returns frontend URL of entity.
 */
export const internalURL = id => (`/node/${id}`);

export const composeFollowUpBossData = entity => {
  const url = process.env.NEXT_PUBLIC_FRONT_URL;
  if (entity?.type == 'users' && entity?.roles?.includes('agent')) {
    return (
      {
        "fullname": entity.fullName,
        "email": entity.publicEmail,
        "alias": entity.alias,
        "subtitle": entity.subtitle,
      }
    );
  }
  else if (entity?.type == 'listings') {
    return (
      {
        "street": entity.street,
        "city": entity.city,
        "state": entity.state,
        "code": entity.zip,
        "mlsNumber": entity.mlsNum,
        "price": entity.price,
        "bedrooms": entity.beds || 'n/a',
        "bathrooms": entity.bathAll || 'n/a',
        "area": entity.sqft || 'n/a',
        "lot": entity.lotSize || 'n/a',
        "forRent": entity.saleType == 'Rental' ? '1' : '0',
        "url": entity.alias?.alias ? url + entity.alias?.alias : url + entity.internal,
        "type": (entity.mlsSubtype && entity.mlsSubtype[0]) || '',
      }
    );
  }
  else if (entity?.type == 'listings_old') {
    return (
      {
        "street": entity.street,
        "city": entity.city,
        "state": entity.state,
        "code": entity.zip,
        "mlsNumber": entity.mlsNum,
        "price": entity.price,
        "bedrooms": entity.beds || 'n/a',
        "bathrooms": entity.bathAll || 'n/a',
        "area": entity.sqft || 'n/a',
        "lot": entity.lotSize || 'n/a',
        "forRent": entity.saleType == 'Rental' ? '1' : '0',
        "url": entity.alias?.alias ? url + entity.alias?.alias : url + entity.internal,
        "type": (entity.mlsSubtype && entity.mlsSubtype[0]) || '',
      }
    );
  }
};

export const composeListingStatus = (saleType, sold_status, avail_date, banner, ohDates, customTxt) => {
  banner = banner ? 1 : 0;
  let res = null;
  var options = {year: 'numeric', month: 'long', day: 'numeric'};

  try {
    if (saleType == 'Rental') {
      if (avail_date?.length > 0) {
        res = Object.assign({}, listSaleStatus_4,
            {
              statusBanner: banner,
              label: avail_date,
              strongLabel: '',
            });
      }
    }
    else if (saleType == 'Buy') {
      if (sold_status == 2) {
        res = Object.assign({}, listSaleStatus_2, {statusBanner: banner});
      }
      else if (sold_status == 1) {
        res = Object.assign({}, listSaleStatus_1, {statusBanner: banner});
      }
      else if (sold_status == 3) {
        res = Object.assign({}, listSaleStatus_3, {statusBanner: banner});
      }
      else if (sold_status == 5) {
        res = Object.assign({}, listSaleStatus_5, {statusBanner: banner});
      }
      else if (sold_status == 6) {
        res = Object.assign({}, listSaleStatus_6, {statusBanner: banner});
      }
      else if (sold_status == 7) {
        res = Object.assign({}, listSaleStatus_7, {
          statusBanner: banner,
          label: customTxt,
        });
      }
      else if (sold_status == 8) {
        res = Object.assign({}, listSaleStatus_8, {statusBanner: banner});
      }
      else if (ohDates?.length > 0) {
        ohDates.some((oh) => {
          if (oh.third && oh.first) {
            let timestamp = Date.parse(oh.first);
            if (!isNaN(timestamp) && timestamp > Date.now()) {
              const start = new Date(oh.first);
              res = Object.assign({}, listOpenHouseStatus, {
                statusBanner: banner,
                strongLabel: start.toLocaleDateString("en-US", options) + ' ' + start.toLocaleTimeString("en-US"),
              });
              return res;
            }
          }
        });
      }
    }
  }
  catch(e) {
    console.warn('Banner issue: ', e);
    return null;
  }
  return res;
};

/*
  Format Property Open House dates into array.
  If "return_single == TRUE", return array with only first upcoming result.
*/
export const formatOpenHouseDates = (ohDates, return_single) => {
  return_single = return_single || false;
  var opts_date = {year: 'numeric', month: 'short', day: 'numeric'};
  var opts_time = {timeZone:'America/New_York', hour: 'numeric', minute:'2-digit'};
  var res = [];

  try {
    if (ohDates?.length > 0) {
      ohDates.map((oh, ind) => {
        let d2_stamp = Date.parse(oh.second);
        // Upcoming/ongoing "enabled" date?
        if (oh.third && !isNaN(d2_stamp) && d2_stamp > Date.now()) {
          let d1_stamp = Date.parse(oh.first);
          let d1_date = new Date(d1_stamp);
          let d2_date = new Date(d2_stamp);
          let d1_time = d1_date.toLocaleTimeString("en-US", opts_time);
          let d2_time = d2_date.toLocaleTimeString("en-US", opts_time);
          var day1 = d1_date.getDate();
          var day2 = d2_date.getDate();

          if (day1 == day2) {
            if (d1_time == d2_time) {
              let d_s = d1_date.toLocaleDateString("en-US", opts_date) + ' | ' + d1_time;
              res.push(`${d_s}`);
            }
            else {
              let d_s = d1_date.toLocaleDateString("en-US", opts_date) + ' | ' + d1_time;
              let d_e = d2_time;
              res.push(`${d_s} - ${d_e}`);
            }
          }
          else {
            let d_s = d1_date.toLocaleDateString("en-US", opts_date) + ' ' + d1_time;
            let d_e = d2_date.toLocaleDateString("en-US", opts_date) + ' ' + d2_time;
            res.push(`${d_s} - ${d_e}`);
          }
        }
      });
    }
  }
  catch(e) {
    console.warn('OPEN HOUSE error: ', e);
    return null;
  }

  if (return_single && res.length) {
    return [res[0]];
  }

  return res;
};

export const composeRangeSliderMarks = (slider, type) => {
  let opts = [];
  type = type || 'Buy';

  if (slider == 'price') {
    let k = 0;
    if (type == 'Buy') {
      for (let i = 0; i < 1000000; i += 50000) {
        opts.push({
          value: k,
          scaledValue: i,
        });
        k++;
      }
      for (let i = 1000000; i < 3000000; i += 500000) {
        opts.push({
          value: k,
          scaledValue: i,
        });
        k++;
      }
      for (let i = 3000000; i <= 5000000; i += 1000000) {
        opts.push({
          value: k,
          scaledValue: i,
        });
        k++;
      }
    }
    else if (type == 'Rental') {
      for (let i = 0; i < 2000; i += 200) {
        opts.push({
          value: k,
          scaledValue: i,
        });
        k++;
      }
      for (let i = 2000; i < 4000; i += 250) {
        opts.push({
          value: k,
          scaledValue: i,
        });
        k++;
      }
      for (let i = 4000; i <= 10000; i += 500) {
        opts.push({
          value: k,
          scaledValue: i,
        });
        k++;
      }
    }
  }
  else if (slider == 'gift') {
    let k = 0;
    for (let i = 25; i <= 250; i += 25) {
      opts.push({
        value: i,
        scaledValue: i,
        label: `$ ${i}`,
      });
      k++;
    }
  }
  else if (slider == 'sqft') {
    let k = 0;
    for (let i = 0; i < 1000; i += 250) {
      opts.push({
        value: k,
        scaledValue: i,
      });
      k++;
    }
    for (let i = 1000; i <= 6000; i += 200) {
      opts.push({
        value: k,
        scaledValue: i,
      });
      k++;
    }
  }
  else if (slider == 'acres') {
    let k = 0;
    for (let i = 0; i < 1; i += .25) {
      opts.push({
        value: k,
        scaledValue: i,
      });
      k++;
    }
    for (let i = 1; i <= 3; i += .5) {
      opts.push({
        value: k,
        scaledValue: i,
      });
      k++;
    }
    opts.push({
      value: k++,
      scaledValue: 5,
    });
    opts.push({
      value: k++,
      scaledValue: 10,
    });
    opts.push({
      value: k++,
      scaledValue: 20,
    });
  }
  return opts;
};
