export const listSaleStatus = {
  'Active Under Contract': 1,
  'BG Active Under Contract': 1,
  'Under Contract': 2,
  'Under Contract 72 hr. Cont.': 6,
  'BG Under Contract - 72 hr. Cont.': 6,
  'BG Under Contract': 2,
  'Sold': 3,
  'BG Sold': 3,
  'Rented': 4,
  'Active': 5,
  'BG Active': 5,
  'Custom Banner': 7,
  'Coming Soon': 8,
};

export const listSaleStatus_3 = {
  status: 3,
  label: 'Sold',
  strongLabel: '',
  statusColor: 'white',
  statusBgColor: 'statusActive',
  statusBanner: 0,
};

export const listSaleStatus_4 = {
  status: 4,
  label: 'Leased until',
  strongLabel: 'February 28, 2022',
  statusColor: 'black',
  statusBgColor: 'primary.light',
  statusBanner: 0,
};

export const listSaleStatus_2 = {
  status: 2,
  label: 'Under',
  strongLabel: 'Contract',
  statusColor: 'warning.contrastText',
  statusBgColor: 'warning.main',
  statusBanner: 0,
};

export const listSaleStatus_1 = {
  status: 1,
  label: 'Active Under',
  strongLabel: 'Contract',
  statusColor: 'warning.contrastText',
  statusBgColor: 'success.lighter',
  statusBanner: 0,
};

export const listSaleStatus_5 = {
  status: 5,
  label: 'Active',
  strongLabel: '',
  statusColor: 'white',
  statusBgColor: 'primary.main',
  statusBanner: 0,
};

export const listSaleStatus_6 = {
  status: 6,
  label: 'Under Contract',
  strongLabel: '72 hr. Cont.',
  statusColor: 'success.contrastText',
  statusBgColor: 'success.light',
  statusBanner: 0,
};

export const listSaleStatus_7 = {
  status: 7,
  label: '',
  strongLabel: '',
  statusColor: 'warning.main',
  statusBgColor: 'transparent',
  statusBanner: 0,
  message: '',
};

export const listSaleStatus_8 = {
  status: 8,
  label: 'Coming Soon',
  strongLabel: '',
  statusColor: 'white',
  statusBgColor: 'text.main',
  statusBanner: 0,
};

export const listOpenHouseStatus = {
  status: 1,
  label: 'OPEN HOUSE ',
  strongLabel: 'October 15, 2021',
  statusColor: 'success.contrastText',
  statusBgColor: 'success.main',
  statusBanner: 0,
};

export const listImages = [{
  src: "https://images.unsplash.com/photo-1599809275671-b5942cabc7a2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2850&q=80",
  imgAlt: "Main",
  imgTitle: "Main",
},
  {
    src: "https://images.unsplash.com/photo-1570129477492-45c003edd2be?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=5070&q=80",
    imgAlt: "Test",
    imgTitle: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1568605114967-8130f3a36994?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2670&q=80",
    imgAlt: "Test",
    imgTitle: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1523217582562-09d0def993a6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2680&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1600596542815-ffad4c1539a9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2675&q=80",
    imgAlt: "Test",
    imgTitle: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1570129477492-45c003edd2be?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=5070&q=80",
    imgAlt: "Test",
    imgTitle: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1568605114967-8130f3a36994?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2670&q=80",
    imgAlt: "Test",
    imgTitle: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1523217582562-09d0def993a6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2680&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1570129477492-45c003edd2be?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=5070&q=80",
    imgAlt: "Test",
    imgTitle: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1568605114967-8130f3a36994?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2670&q=80",
    imgAlt: "Test",
    imgTitle: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1523217582562-09d0def993a6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2680&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1613490493576-7fde63acd811?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2851&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1566908829550-e6551b00979b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2849&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1566908829550-e6551b00979b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2849&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1613490493576-7fde63acd811?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2851&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1566908829550-e6551b00979b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2849&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1613490493576-7fde63acd811?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2851&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1570129477492-45c003edd2be?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=5070&q=80",
    imgAlt: "Test",
    imgTitle: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1568605114967-8130f3a36994?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2670&q=80",
    imgAlt: "Test",
    imgTitle: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1523217582562-09d0def993a6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2680&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1523217582562-09d0def993a6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2680&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1566908829550-e6551b00979b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2849&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1613490493576-7fde63acd811?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2851&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1566908829550-e6551b00979b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2849&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1566908829550-e6551b00979b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2849&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1566908829550-e6551b00979b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2849&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1613490493576-7fde63acd811?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2851&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1566908829550-e6551b00979b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2849&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1613490493576-7fde63acd811?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2851&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1570129477492-45c003edd2be?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=5070&q=80",
    imgAlt: "Test",
    imgTitle: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1568605114967-8130f3a36994?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2670&q=80",
    imgAlt: "Test",
    imgTitle: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1523217582562-09d0def993a6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2680&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
];


export const Listing1 = {
  id: '11123233-2342342-23234-23424353',
  url: '/listings/11123233-2342342-23234-23424353',
  beds: 3,
  bathAll: '1',
  sqft: '4,252 SF.',
  address: '48123 Another Road ',
  price: 750000,
  daysListed: 3,
  homeStatus: {
    title: 'Sold'
  },
  // label: 'Recently',
  // strongLabel: 'Sold',
  // statusColor: 'warning.contrastText',
  // statusBgColor: 'warning.main',
  imgAlt: "Alt text",
  imgTitle: "Title text",
  imageOrig: 'https://images.unsplash.com/photo-1599809275671-b5942cabc7a2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2850&q=80',
  alias: '/listing/buy/8237648276',
  internal: '/node/45',
  internalId: 45,
  nid: 45,
  body: "<p>This beautiful 2 story, 2 bedrooms, 2.5 bathroom townhouse is&nbsp;available to rent&nbsp;in the gated FishHawk Ridge enclave of the much desired FishHawk Ranch community.&nbsp;Enjoy full access to all FishHawk's award-winning amenities including tennis courts, pools, fitness centers, 30+ miles of walking trails and \"A\" rated schools.</p>\r\n\r\n<p>The home features stunning wood tile throughout the entire first DEoor, a formal dining room with custom lighting, a downstairs half bath, ceiling fan in the living room, and plush carpeting up the stairs and in the bedrooms.&nbsp;</p>\r\n\r\n<p>Overlooking the living room with lanai access and conservation views, the bright kitchen boasts crisp cabinetry, granite countertops, subway tiled backsplash, and a breakfast bar to enjoy casual meals.&nbsp; Appliances include a sleek black refrigerator, range, microwave, and dishwasher.</p>\r\n\r\n<p>Up the stairs, you'll find a laundry closet&nbsp;and the 2 spacious bedrooms.&nbsp; Both bedrooms have dual closets for extra storage and en-suite bathrooms with wood tile DEooring and granite countertops.</p>\r\n\r\n<p>Complete lawn maintenance, including mowing, shrub pruning, irrigation system service, turf, and plant fertilization and plant pest control are included in rent services saving you time and money!</p>\r\n\r\n<p>Enjoy use of the neighborhood pool, exclusive to FishHawk Ridge residents!&nbsp;</p>\r\n",
  dateAvail: "2021-12-08",
  garage: {first: 14, second: 23},
  saleStatus: 4,
  mlsNum: "",
  saleType: "Floor Plan",
  slides: listImages,
  yearBuilt: 2005,
  virtual: "http://some-virtual-tour.com",
  lat: 27.983125,
  lon: -82.45131,
  virtualTour: true,
  sfhouse: {
    id: 34545,
    address: "21 Sunset Strip Dr, Seaside",
    beds: 3,
    bathAll: 1,
    bathFull: 0,
    bathHalf: 0,
    sqft: 2323,
    state: "DE",
    city: 'Long Neck',
    street: "21 Sunset Strip Dr",
    zip: "19966",
    title: "21 Sunset Strip Dr",
    shed: {first: '14', second: '14'},
    deck: {first: '4', second: '12'},
      amenities: [
        "Range - Gas",
        "Refrigerator",
        "Microwave",
        "Dishwasher",
        "CAC",
        "Water Heater - Electric",
        "Screened Porch"
    ],
    houseFiles: [
      {
        src: "https://www.potnets.com/file/49633/download?token=fYA-Pmtb",
      },
      {
        src: "https://www.potnets.com/file/49633/download?token=fYA-Pmtb",
      },
      {
        src: "https://www.potnets.com/file/49633/download?token=fYA-Pmtb",
      },
    ]
  }
};

export const Listing2 = {
  id: '984958-2342342-23234-23424353',
  url: '/listings/984958-2342342-23234-23424353',
  beds: 2,
  bathAll: '1',
  sqft: '2,200 SF.',
  address: '1233 Fake Street',
  daysListed: 3,
  status: 'sold',
  label: 'Under',
  strongLabel: 'Contract',
  statusColor: 'success.contrastText',
  statusBgColor: 'success.main',
  price: 170000,
  homeStatus: {
    title: 'Under Contract'
  },
  imgAlt: "Alt text",
  imgTitle: "Title text",
  imageOrig: 'https://images.unsplash.com/photo-1613490493576-7fde63acd811?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2851&q=80',
  alias: '/listing/rent/232423',
  internal: '/node/23',
  nid: 23,
  internalId: 23,
  garage: {first: 14, second: 23},
  saleStatus: 1,
  mlsNum: "",
  saleType: "Pre-Owned",
  slides: listImages,
  yearBuilt: 2005,
  virtual: "http://some-virtual-tour.com",
  lat: 27.983125,
  lon: -82.45131,
  virtualTour: true,
  sfhouse: {
    id: 34545,
    address: "21 Sunset Strip Dr, Seaside",
    beds: 3,
    bathAll: 1,
    bathFull: 0,
    bathHalf: 0,
    sqft: 2323,
    state: "DE",
    city: 'Long Neck',
    street: "21 Sunset Strip Dr",
    zip: "19966",
    title: "21 Sunset Strip Dr",
    shed: {first: '14', second: '14'},
    deck: {first: '4', second: '12'},
      amenities: [
        "Range - Gas",
        "Refrigerator",
        "Microwave",
        "Dishwasher",
        "CAC",
        "Water Heater - Electric",
        "Screened Porch"
    ],
    houseFiles: [
      {
        src: "https://www.potnets.com/file/49633/download?token=fYA-Pmtb",
      },
      {
        src: "https://www.potnets.com/file/49633/download?token=fYA-Pmtb",
      },
      {
        src: "https://www.potnets.com/file/49633/download?token=fYA-Pmtb",
      },
    ]
  }
};

export const Listing3 = {
  id: '774875-2342342-23234-23424353',
  url: '/listings/774875-2342342-23234-23424353',
  beds: 5,
  price: 490000,
  bathAll: null,
  sqft: 3200,
  address: '999 Fake Street Long Neck, DE 33467',
  daysListed: 3,
  status: 'sold',
  label: 'For',
  strongLabel: 'Sale',
  statusColor: 'primary.contrastText',
  statusBgColor: 'primary.main',
  imgAlt: "Alt text",
  imgTitle: "Title text",
  imageOrig: 'https://images.unsplash.com/photo-1566908829550-e6551b00979b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2849&q=80',
  alias: '',
  internal: '/node/11',
  nid: 11,
  internalId: 11,
  statusBanner: 1,
  saleType: "Pre-Owned",
  saleStatus: 4,

};

export const Listing4 = {
  id: '234234-644564-23234-23424353',
  url: '/listings/234234-644564-23234-23424353',
  beds: 2,
  price: 660000,
  bathAll: 2,
  sqft: 1900,
  address: '66 No Image Street',
  daysListed: null,
  status: 'sold',
  label: 'For',
  strongLabel: 'Sale',
  statusColor: 'primary.contrastText',
  statusBgColor: 'primary.main',
  imgAlt: "Alt text",
  imgTitle: "Title text",
  imageOrig: 'https://images.unsplash.com/photo-1613490493576-7fde63acd811?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2851&q=80',
  alias: '',
  internal: '/node/78',
  nid: 78,
  internalId: 78,
  statusBanner: 1,
};

export const Listing5 = {
  id: '234234-644564-23234-23424353',
  url: '/listings/234234-644564-23234-23424353',
  beds: 4,
  bathAll: 2.5,
  floorplanName: 'The Innisbrook',
  saleType: 'Floorplan',
  imgAlt: "Alt text",
  imgTitle: "Title text",
  imageOrig: 'https://images.unsplash.com/photo-1613490493576-7fde63acd811?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2851&q=80',
  alias: '',
  internal: '/node/78',
  nid: 78,
  internalId: 78,
  statusBanner: 1,
  sqft: 1900,
};

export const Rental1 = {
  title: "21 Sunset Strip Dr",
  id: '11123233-2342342-23234-23424353',
  url: '/property/rental/11123233-2342342-23234-23424353',
  address: "21 Sunset Strip Dr",
  beds: 3,
  bathAll: 1,
  sqft: 2323,
  state: "DE",
  city: 'Long Neck',
  street: "21 Sunset Strip Dr",
  zip: "19966",
  price: 750000,
  // homeStatus: {
  //   title: 'Sold'
  // },
  imgAlt: "Alt text",
  imgTitle: "Title text",
  imageOrig: 'https://images.unsplash.com/photo-1599809275671-b5942cabc7a2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2850&q=80',
  alias: '/listing/buy/8237648276',
  internal: '/node/45',
  internalId: 45,
  nid: 45,
  body: "<p>This beautiful 2 story, 2 bedrooms, 2.5 bathroom townhouse is&nbsp;available to rent&nbsp;in the gated FishHawk Ridge enclave of the much desired FishHawk Ranch community.&nbsp;Enjoy full access to all FishHawk's award-winning amenities including tennis courts, pools, fitness centers, 30+ miles of walking trails and \"A\" rated schools.</p>\r\n\r\n<p>The home features stunning wood tile throughout the entire first DEoor, a formal dining room with custom lighting, a downstairs half bath, ceiling fan in the living room, and plush carpeting up the stairs and in the bedrooms.&nbsp;</p>\r\n\r\n<p>Overlooking the living room with lanai access and conservation views, the bright kitchen boasts crisp cabinetry, granite countertops, subway tiled backsplash, and a breakfast bar to enjoy casual meals.&nbsp; Appliances include a sleek black refrigerator, range, microwave, and dishwasher.</p>\r\n\r\n<p>Up the stairs, you'll find a laundry closet&nbsp;and the 2 spacious bedrooms.&nbsp; Both bedrooms have dual closets for extra storage and en-suite bathrooms with wood tile DEooring and granite countertops.</p>\r\n\r\n<p>Complete lawn maintenance, including mowing, shrub pruning, irrigation system service, turf, and plant fertilization and plant pest control are included in rent services saving you time and money!</p>\r\n\r\n<p>Enjoy use of the neighborhood pool, exclusive to FishHawk Ridge residents!&nbsp;</p>\r\n",
  dateAvail: "2021-12-08",
  // garage: {first: 14, second: 23},
  mlsNum: "",
  slides: listImages,
  yearBuilt: 2005,
  virtual: "http://some-virtual-tour.com",
  lat: 27.983125,
  lon: -82.45131,
};

export const RentalReview1 = {
  id: '11123233-2342342-23234-23424353',
  title: 'Great Home!',
  // url: '/property/rental/11123233-2342342-23234-23424353',
  address: "21 Sunset Strip Dr",
  state: "DE",
  city: 'Long Neck',
  street: "21 Sunset Strip Dr",
  zip: "19966",
  alias: '/listing/buy/8237648276',
  internal: '/node/45',
  internalId: 45,
  nid: 45,
  body: "Everything as described, beautiful house, nice location,clean!",
  dateAvail: "2021-12-08",
  // garage: {first: 14, second: 23},
  mlsNum: "",
  slides: listImages,
  yearBuilt: 2005,
  virtual: "http://some-virtual-tour.com",
  lat: 27.983125,
  lon: -82.45131,
};

export const FloorplanCta1 = {
  id: '234234-644564-23234-23424353',
  url: '/listings/234234-644564-23234-23424353',
  beds: 4,
  bathAll: '3.5',
  floorplanName: 'The Innisbrook',
  floorplanLink1: '/XYZ.pdf',
  floorplanLink2: '/ABC.pdf',
  saleType: 'Floorplan',
  imgAlt: "Alt text",
  imgTitle: "Title text",
  imageOrig: 'https://images.unsplash.com/photo-1613490493576-7fde63acd811?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2851&q=80',
  alias: '',
  internal: '/node/78',
  nid: 78,
  internalId: 78,
  statusBanner: 1,
  sqft: 3420,
};

export const FloorplanCta2 = {
  id: '234234-644564-23234-23424353',
  url: '/listings/234234-644564-23234-23424353',
  beds: 3,
  bathAll: '2',
  floorplanName: 'The Pearl',
  floorplanLink1: '/XYZ.pdf',
  saleType: 'Floorplan',
  imgAlt: "Alt text",
  imgTitle: "Title text",
  imageOrig: 'https://images.unsplash.com/photo-1613490493576-7fde63acd811?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2851&q=80',
  alias: '',
  internal: '/node/78',
  nid: 78,
  internalId: 78,
  statusBanner: 1,
  sqft: 3420,
};

export const FloorplanCta3 = {
  id: '234234-644564-23234-23424353',
  url: '/listings/234234-644564-23234-23424353',
  beds: 4,
  bathAll: '2.5',
  floorplanName: 'The Westchester',
  saleType: 'Floorplan',
  imgAlt: "Alt text",
  imgTitle: "Title text",
  imageOrig: 'https://images.unsplash.com/photo-1613490493576-7fde63acd811?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2851&q=80',
  alias: '',
  internal: '/node/78',
  nid: 78,
  internalId: 78,
  statusBanner: 1,
  sqft: 3420,
};

export const AgentSlide1 = {
  id: '234234-644564-23255-23424353',
  internalId: 11,
  imgAlt: "Alt text",
  imgTitle: "Title text",
  imageOrig: 'https://www.potnets.com/sites/default/files/agents-images/hdietzws2.jpg',
  title: 'John Smith',
  subtitle: 'REALTOR | ABC',
  body: 'Your landlord resource, Bill oversees onboarding new homes and is the main point of contact for all things owner related.',
  alias: "https://www.potnets.com"
};

export const AgentSlide2 = {
  id: '234234-644564-11234-23424353',
  internalId: 22,
  imgAlt: "Alt text",
  imgTitle: "Title text",
  imageOrig: 'https://www.potnets.com/sites/default/files/agents-images/jrodriguezws.jpg',
  title: 'John Smith',
  subtitle: 'REALTOR | ABC',
  body: 'Your landlord resource, Bill oversees onboarding new homes and is the main point of contact for all things owner related.',
  alias: "https://www.potnets.com"
};

export const AgentSlide3 = {
  id: '234234-644564-99090-23424353',
  internalId: 23,
  imgAlt: "Alt text",
  imgTitle: "Title text",
  imageOrig: 'https://www.potnets.com/sites/default/files/agents-images/jnunnallyws2021.jpg',
  title: 'John Smith',
  subtitle: 'REALTOR | ABC',
  body: 'Your landlord resource, Bill oversees onboarding new homes and is the main point of contact for all things owner related.',
  alias: "https://www.potnets.com"
};

export const AgentSlide4 = {
  id: '234234-644564-23234-23222353',
  internalId: 211,
  imgAlt: "Alt text",
  imgTitle: "Title text",
  imageOrig: 'https://www.potnets.com/sites/default/files/agents-images/pbastws.jpg',
  title: 'John Smith',
  subtitle: 'REALTOR | ABC',
  body: 'Your landlord resource, Bill oversees onboarding new homes and is the main point of contact for all things owner related.',
  alias: "https://www.potnets.com"
};

export const AgentSlide5 = {
  id: '234234-644564-23234-23433353',
  internalId: 223,
  imgAlt: "Alt text",
  imgTitle: "Title text",
  imageOrig: 'https://www.potnets.com/sites/default/files/agents-images/hdietzws2.jpg',
  title: 'John Smith',
  subtitle: 'REALTOR | ABC',
  body: 'Your landlord resource, Bill oversees onboarding new homes and is the main point of contact for all things owner related.',
  alias: "https://www.potnets.com"
};

export const AgentSlide6 = {
  id: '234234-644564-23234-11424353',
  internalId: 44,
  imgAlt: "Alt text",
  imgTitle: "Title text",
  imageOrig: 'https://www.potnets.com/sites/default/files/agents-images/jrodriguezws.jpg',
  title: 'John Smith',
  subtitle: 'REALTOR | ABC',
  body: 'Your landlord resource, Bill oversees onboarding new homes and is the main point of contact for all things owner related.',
  alias: "https://www.potnets.com",
  email: 'shawn@potnets.com',
  address: [{address_phonenumber: '(813)-672-8022'}],
  phoneExt: '115'
};

export const AgentListing1 = {
  id: '234234-644564-23255-23424353',
  internalId: 11,
  imgAlt: "Alt text",
  imgTitle: "Title text",
  imageOrig: 'https://images.unsplash.com/photo-1627161683077-e34782c24d81?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1406&q=80',
  contact: [
    {
      "first": "18009997777",
      "second": "stibbs@potnets.com"
    }
  ],
  info: {
    "first": "Maria Test",
    "second": "Realtor",
  },
  summary: '<p> Susie joined the Tunnell team after completing a long and successful career in sales management, marketing, and the training of management personnel.</p><p>She grew up in West Virginia where she attended Bluefield State College before moving to the Washington D.C. area. Her career took her to New York and then to Virginia Beach, Virginia, where she met her husband, a Navy pilot. They are blessed with one amazing son, and have two lovable dogs. Between her corporate career and her husband’s Navy career, she has called many places home, but since 2008, she has come to truly love the active, scenic, and fun lifestyle Florida has to offer.</p><p>Susie combines knowledge of marketing, strong attention to detail; project management, negotiation skills, and business savvy with her ability and passion to help clients achieve their goals. These skills are not stand alone, but are greatly enhanced when blended with her many personal experiences as a homebuyer of existing and new construction homes, a seller, a landlord and tenant.</p><p>Whether you are relocating or just looking for a new place to call home, Susie is ready and eager to assist you with all your real estate needs!</p>',
  body: '<p>Susie joined the Tunnell team after completing a long and successful career in sales management, marketing, and the training of management personnel.</p><p> She grew up in West Virginia where she attended Bluefield State College before moving to the Washington D.C. area. Her career took her to New York and then to Virginia Beach, Virginia, where she met her husband, a Navy pilot. They are blessed with one amazing son, and have two lovable dogs. Between her corporate career and her husband’s Navy career, she has called many places home, but since 2008, she has come to truly love the active, scenic, and fun lifestyle Florida has to offer.</p><p> Susie combines knowledge of marketing, strong attention to detail; project management, negotiation skills, and business savvy with her ability and passion to help clients achieve their goals. These skills are not stand alone, but are greatly enhanced when blended with her many personal experiences as a homebuyer of existing and new construction homes, a seller, a landlord and tenant.</p><p> Whether you are relocating or just looking for a new place to call home, Susie is ready and eager to assist you with all your real estate needs!</p>',
  alias: "https://www.potnets.com",
  social: [
    {first: 'Zillow', second: "https://www.zillow.com/profile/Susie%20Tibbs"},
    {first: 'Facebook', second: "https://www.facebook.com/Susie-Tibbs-Realtor-100689788364833/"},
    {first: 'Linkedin', second: "https://www.linkedin.com/in/susie-tibbs-566835149"},
    {first: 'Twitter', second: "https://twitter.com/potnets?lang=en"},
  ],
  address: [
    {
      "country_code": "US",
      "administrative_area": "",
      "locality": "",
      "dependent_locality": null,
      "postal_code": "",
      "sorting_code": null,
      "address_line1": "N/A",
      "address_line2": "",
      "organization": "",
      "given_name": "",
      "additional_name": null,
      "family_name": "",
      "address_phonenumber": "(813) 431-2701",
      "address_faxnumber": ""
    }
  ],
};
